import {
  SET_MISC
} from '../../constant'

const initialState = {
  showLoading: false,
  snackbarMessage: null
}

export default function MiscReduer (state = initialState, action) {
  switch (action.type) {
    case SET_MISC: {
      return {
        ...state,
        ...action.payload
      }
    }
    default: {
      return state
    }
  }
}
