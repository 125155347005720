import _ from 'lodash'
import {
  SET_PROSPECTS,
  ADD_WHATSAPP_LINK,
  UPDATE_WHATSAPP_LINK,
  DELETE_WHATSAPP_LINK,
  ADD_N2WL,
  UPDATE_N2WL,
  DELETE_N2WL,
  ADD_NUMBER
} from '../../constant'

const initialState = {
  fetched: false,
  configuration: [],
  whatsappLinks: [],
  n2wls: [],
  contactNumbers: []
}

export default function ProspectsReducer (state = initialState, action) {
  switch (action.type) {
    case SET_PROSPECTS: {
      if (_.isArray(action.payload.whatsappLinks)) {
        action.payload.whatsappLinks = _.orderBy([...action.payload.whatsappLinks], 'label', 'asc')
      }
      action.payload.fetched = true
      return { ...state, ...action.payload }
    }
    case ADD_WHATSAPP_LINK: {
      const whatsappLinksClone = [...state.whatsappLinks]
      whatsappLinksClone.push(action.payload)
      return { ...state, ...{ whatsappLinks: whatsappLinksClone } }
    }
    case UPDATE_WHATSAPP_LINK: {
      const whatsappLinksClone = [...state.whatsappLinks]
      const index = _.findIndex(whatsappLinksClone, { id: action.payload.id })
      if (index !== -1) {
        whatsappLinksClone[index] = action.payload
      }
      return { ...state, ...{ whatsappLinks: whatsappLinksClone } }
    }
    case DELETE_WHATSAPP_LINK: {
      const whatsappLinksClone = [...state.whatsappLinks]
      const index = _.findIndex(whatsappLinksClone, { id: action.payload })
      if (index !== -1) {
        whatsappLinksClone.splice(index, 1)
      }
      return { ...state, ...{ whatsappLinks: whatsappLinksClone } }
    }
    case ADD_N2WL: {
      const n2wlsClone = [...state.n2wls]
      n2wlsClone.push(action.payload)
      return { ...state, ...{ n2wls: n2wlsClone } }
    }
    case UPDATE_N2WL: {
      const n2wlsClone = [...state.n2wls]
      const index = _.findIndex(n2wlsClone, { id: action.payload.id })
      if (index !== -1) {
        n2wlsClone[index] = action.payload
      }
      return { ...state, ...{ n2wls: n2wlsClone } }
    }
    case DELETE_N2WL: {
      const n2wlsClone = [...state.n2wls]
      const index = _.findIndex(n2wlsClone, { id: action.payload })
      if (index !== -1) {
        n2wlsClone.splice(index, 1)
      }
      return { ...state, ...{ n2wls: n2wlsClone } }
    }
    case ADD_NUMBER: {
      const contactNumbersClone = [...state.contactNumbers]
      contactNumbersClone.splice(0, 0, action.payload)
      return { ...state, contactNumbers: contactNumbersClone }
    }
    default: {
      return state
    }
  }
}
