import React, { useEffect, useRef, useState } from 'react'

import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import LinearProgress from '@mui/material/LinearProgress'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'

const StyledDialogContent = styled(DialogContent)`
  ${({ theme }) => `
    padding-bottom: ${theme.spacing(3)};
    
    #fb-post-container-loader-uniq {
      min-width: 500px;
      min-height: 200px;
      display: flex;
      align-items: center;
      justify-content: center
    }
  `}
`

export default function ViewFBPost (props) {
  const { pageFbId, postId, onClose } = props

  const [isLoading, setIsLoading] = useState(true)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const timeout = useRef()

  useEffect(() => {
    timeout.current = setTimeout(() => {
      const element = document.getElementById('fb-post-container-loader-uniq')
      if (element) {
        FB.XFBML.parse(element, () => {
          setIsLoading(false)
        })
      }
    }, 600)

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [])

  return (
    <Dialog open fullScreen={isMobile} onClose={isLoading? () => {} : onClose} disableEscapeKeyDown={isLoading}>
      {
        isLoading && <LinearProgress />
      }
      <DialogTitle>Preview Facebook Post</DialogTitle>
      <StyledDialogContent>
        <div id='fb-post-container-loader-uniq'>
          <div className='fb-post' data-href={`https://facebook.com/${pageFbId}/posts/${postId}/`} />
        </div>
      </StyledDialogContent>
      <DialogActions>
        <Button
          fullWidth={isMobile}
          variant='outlined'
          onClick={onClose}
          disabled={isLoading} >
          Tutup
        </Button>
      </DialogActions>
    </Dialog>
  )
}
