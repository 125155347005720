import React from 'react'
import {connect} from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Alert from '@mui/material/Alert'

import {SET_MISC} from '../../constant'

const StyledDialogContent = styled(DialogContent)`
  ${({theme}) => `
    padding-bottom: ${theme.spacing(3)};
    
    .code-info-container {
      margin-bottom: ${theme.spacing(2)};
    }
    
    button {
      margin-top: ${theme.spacing(2)};
    }
    
    .code {
      padding: ${theme.spacing(2)};
      background-color: ${theme.palette.grey['50']};
      border: 1px dotted ${theme.palette.grey['900']};
    }
  `}
`

const CodeCopyDialog = function CodeCopyDialog(props) {
  const { title, alertText, onClose = () => {}, code = 'Bizsaya set .......\nBizsaya set .......', setSnackbarMsg, ...others } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  function onCopy () {
    setSnackbarMsg('Kod arahan telah disalin secara automatik')
    onClose()
  }

  return (
    <Dialog fullScreen={isMobile} onClose={onClose} {...others}>
      <DialogTitle>Tambah FB Post untuk {title}</DialogTitle>
      <StyledDialogContent>
        <Alert severity={'info'}>
          Salin kod arahan dibawah dan tampal kod arahan tersebut pada komen FB Post tersebut untuk
          menetapkan FB Post yang akan digunakan untuk {alertText} ini. Sila tampal kod arahan
          menggunakan akaun Page anda
        </Alert>

        <Grid container mt={2}>
          <Grid item xs={12}>
            <Typography className='code' variant='body1' color='secondary'>
              {code}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CopyToClipboard text={code} onCopy={onCopy}>
              <Button
                fullWidth
                variant='outlined'
                color='primary'>
                Salin kod arahan secara automatik
              </Button>
            </CopyToClipboard>
          </Grid>
        </Grid>

      </StyledDialogContent>
      <DialogActions>
        <Button
          fullWidth={isMobile}
          variant='outlined'
          onClick={onClose}>
          Tutup
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
    setSnackbarMsg: (snackbarMessage = null) => {
      dispatch({ type: SET_MISC, payload: { snackbarMessage } })
    }
  }
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CodeCopyDialog)
