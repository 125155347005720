import React from 'react'

import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContentText from '@mui/material/DialogContentText'
import ButtonGroup from '@mui/material/ButtonGroup'

const DialogActionsStyled = styled(DialogActions)`
  ${({theme}) => `
  	
  `}
`

export default function DeleteConfirmationDialog(props) {

	const {onClose, confirm} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	return (
		<Dialog open={true} fullScreen={isMobile} onClose={onClose}>
			<DialogTitle>Padam tetapan auto reply</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Padam tetapan auto reply Facebook Pages ini?
				</DialogContentText>
			</DialogContent>
			<DialogActionsStyled>
				<ButtonGroup fullWidth={isMobile}>
					<Button
						fullWidth={isMobile}
						color={'warning'}
						variant='outlined'
						onClick={onClose} >
						Batal
					</Button>
					<Button
						fullWidth={isMobile}
						color='error'
						variant='outlined'
						onClick={confirm} >
						Padam
					</Button>
				</ButtonGroup>
			</DialogActionsStyled>
		</Dialog>
	)
}
