import {
  SET_HOMEPAGE
} from '../../constant'

const initialState = {}

export default function HomepageReducer (state = initialState, action) {
  switch (action.type) {
    case SET_HOMEPAGE: {
      return { ...state, ...action.payload }
    }
    default: {
      return state
    }
  }
}
