import React from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'

import {useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'

import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined'
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined'

import {getProfile} from '../../../actions/profile'
import {getAuthLink} from '../../../actions/facebook'
import {getProspect} from '../../../actions/prospects'
import {SET_MISC} from '../../../constant'

const HeadingBtn = function HeadingBtn(props) {

	const {
		setLoading,
		toPay,
		setOpenPaymentDialog
	} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	function reAuth() {
		setLoading(true)
		getAuthLink('reauth')
			.then(response => {
				if (response.data && response.data.url) {
					window.location.href = response.data.url
				}
			})
			.catch(err => console.error(err))
	}

	//
	// function save() {
	// 	if (!_.isEmpty(pagesToSwap)) {
	// 		setLoading(true)
	// 		swapPages(pagesToSwap)
	// 			.then(() => {
	// 				setPagesToSwap([])
	// 				setMsg('Page telah di "swap"')
	// 				setMsgType('success')
	// 				return reFetchDetail()
	// 			})
	// 			.catch(err => {
	// 				setMsg('Ralat sewaktu "swap" page')
	// 				setMsgType('error')
	// 			})
	// 			.then(() => setLoading(false))
	// 	}
	//
	// 	if (!_.isEmpty(pagesToAdd)) {
	// 		setLoading(true)
	// 		addPage(pagesToAdd)
	// 			.then(() => {
	// 				setPagesToAdd([])
	// 				setMsg('Page telah ditambah')
	// 				setMsgType('success')
	// 				return reFetchDetail()
	// 			})
	// 			.catch(err => {
	// 				if (err.response) {
	// 					setMsgType('error')
	//
	// 					if (err.response.status === 500) {
	// 						setMsg('Ralat sewaktu menambah page')
	// 					} else {
	// 						setMsg(err.response.data.msg)
	// 					}
	// 				}
	// 			})
	// 			.then(() => setLoading(false))
	// 	}
	// }

	return (
		<ButtonGroup
			orientation={isMobile ? 'vertical' : 'horizontal'}
			fullWidth={isMobile} variant="outlined"
			color={'primary'}>
			<Button color={'secondary'} startIcon={<AutorenewOutlinedIcon/>} onClick={reAuth}>
				Perbaharui akses
			</Button>
			<Button
				startIcon={<PaidOutlinedIcon/>}
				onClick={() => setOpenPaymentDialog(true)}
				disabled={_.isEmpty(toPay)}
			>
				Pembayaran
			</Button>
		</ButtonGroup>
	)
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		setLoading: (showLoading = false) => {
			dispatch({type: SET_MISC, payload: {showLoading}})
		},
		reFetchDetail: () => {
			dispatch(getProfile())
			dispatch(getProspect())
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HeadingBtn)