import React, { useEffect, useState } from 'react'
import {connect} from 'react-redux'

import _ from 'lodash'

import {styled} from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

import Tab1 from './tab1'
import Tab2 from './tab2'
import Tab3 from './tab3'

const {parse}  = require('../../functions/querystring')

const StyledBox = styled(Box)`
  flex-grow: 1;
`

const Configuration = function Configuration(props) {

  const { location } = props

  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    const qs = parse(location.search.replace(/^\?/, ''))

    if (_.has(qs, 'tab')) {
      setTabIndex(isNaN(parseInt(qs.tab)) ? 0 : (parseInt(qs.tab) - 1))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <AppBar position='static' color='default'>
        <Tabs
          value={tabIndex}
          onChange={(e, v) => setTabIndex(v)}
          indicatorColor='primary'
          textColor='primary'
          variant='fullWidth'
          aria-label='full width tabs example'
        >
          <Tab label='Notifikasi' />
          <Tab label='Facebook Pages' />
          <Tab label='Instagram' />
        </Tabs>
      </AppBar>

      <StyledBox>
        {
          tabIndex === 0 && <Tab1 />
        }
        {
          tabIndex === 1 && <Tab2 />
        }
        {
          tabIndex === 2 && <Tab3 />
        }
      </StyledBox>
    </>
  )

}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Configuration)