import React from 'react'
import {connect} from 'react-redux'
import {CopyToClipboard} from 'react-copy-to-clipboard'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'

import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'

import {SET_MISC} from '../../../constant'

const StyledBox = styled(Box)`
  ${({theme}) => `
    
  `}
`

const Begin = function Begin(props) {

	const {code, setSnackbarMsg} = props

	return (
		<StyledBox>

			<Alert severity={'info'}>
				Salin kod arahan dibawah dan tampal kod arahan tersebut ke&nbsp;
				<a href="https://t.me/Bizsaya_bot" target="_blank" rel="noreferrer">BOT Telegram Bizsaya</a>.
				Anda juga boleh tampal kod arahan tersebut didalam
				group Telegram perniagaan anda. Pastikan anda telah add BOT Telegram Bizsaya terlebih dahulu ke group
				Telegram perniagaan anda.
			</Alert>

			<TextField
				sx={{mt: 3}}
				fullWidth
				value={code}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<CopyToClipboard text={code} onCopy={() => setSnackbarMsg('Kod arahan telah disalin')}>
								<Tooltip title={'Salin kod arahan secara automatik'}>
									<IconButton edge="end">
										<FileCopyOutlinedIcon/>
									</IconButton>
								</Tooltip>
							</CopyToClipboard>
						</InputAdornment>
					)
				}}
				label="Kod arahan"
			/>

		</StyledBox>
	)
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		setSnackbarMsg: (snackbarMessage = null) => {
			dispatch({ type: SET_MISC, payload: { snackbarMessage } })
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Begin)