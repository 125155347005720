import React from 'react'
import {connect} from 'react-redux'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import Heading from './heading'
import Paper1 from './paper-1'
import Paper2 from './paper-2'
import Paper3 from './paper-3'

import {SET_MISC} from '../../../constant'
import {updatePage} from '../../../actions/profile'

const StyledBox = styled(Box)`
  ${({theme}) => `
    padding: ${theme.spacing(2)};
    
    a {
			text-decoration: none;
			color: ${theme.palette.info.main};
		}
  `}
`

const Tab4 = function Tab4(props) {

	const { id, fbId, tId, appUserId, setSnackbarMsg, setLoading, updatePage } = props

	function updateThePage (column) {
		column.id = id
		setLoading(true)

		updatePage(column)
			.then(() => setSnackbarMsg('Facebook page telah dikemaskini'))
			.catch(() => setSnackbarMsg('Ralat sewaktu mengemaskini Facebook page'))
			.then(() => setLoading(false))
	}

	return (
		<>
			<Heading />

			<StyledBox>
				<Grid container spacing={2}>
					<Grid item sm={12} md={6}>
						<Paper1 tId={tId} fbId={fbId} updateThePage={updateThePage} setSnackbarMsg={setSnackbarMsg} />
					</Grid>
					<Grid item sm={12} md={6}>
						<Paper2 appUserId={appUserId} fbId={fbId} updateThePage={updateThePage} setSnackbarMsg={setSnackbarMsg} />
					</Grid>
					<Grid item sm={12} md={12}>
						<Paper3 />
					</Grid>
				</Grid>
			</StyledBox>

		</>
	)
}

const mapStateToProps = (state) => {
	return {
		...state.facebook.activePage
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		setLoading: (showLoading = false) => {
			dispatch({ type: SET_MISC, payload: { showLoading } })
		},
		setSnackbarMsg: (snackbarMessage = null) => {
			dispatch({ type: SET_MISC, payload: { snackbarMessage } })
		},
		updatePage: pageToUpdate => {
			return dispatch(updatePage(pageToUpdate))
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Tab4)