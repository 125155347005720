import React from 'react'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

const StyledDiv = styled(Box)`
  ${({ theme }) => `
    padding: ${theme.spacing(2)};
    
    @media (max-width: ${theme.breakpoints.values.md}px) {
      height: auto;
      
      > div > div:last-child {
        margin-top: ${theme.spacing(1)};
      }
    }
    
    @media (max-width: ${theme.breakpoints.values.md}px) {
      button {
        width: 100%;
      }
    }
    
    .primary-btn {
      width: 150px;
      margin-left: ${theme.spacing(1)};
      
      @media (max-width: ${theme.breakpoints.values.md}px) {
        width: 100%;
        margin-left: 0px;
        margin-top: ${theme.spacing(1)};
      }
    }
  `}
`

export default function TabHeading (props) {
  const { children, title, description, titleProps = { xs: 7 }, btnContainerProps = { xs: 5 }, className = '' } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <StyledDiv className={className}>
      <Grid
        container
        direction='row'
        justify={isMobile ? 'flex-start' : 'flex-end'}
        alignItems='flex-start'
      >
        <Grid item {...titleProps}>
          <Typography variant='h6'>{title}</Typography>
          <Typography variant='caption'>{description}</Typography>
        </Grid>
        <Grid
          item
          {...btnContainerProps}
          container
          direction='row'
          justifyContent='flex-end'
          alignItems='flex-start'
        >
          {children}
        </Grid>
      </Grid>
    </StyledDiv>
  )
}
