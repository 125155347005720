import React, {useState} from 'react'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'

const StyledDiv = styled(Box)`
  ${({theme}) => `
    padding: ${theme.spacing(2)};
    
    .alert {
    	margin: ${theme.spacing(2)} 0px;
    }
  `}
`

export default function SubTab2(props) {

	const {formObj, setFormObj, errCode, setErrCode} = props

	const [errMsg, setErrMsg] = useState(false)

	function onChangeMessage(e) {
		setErrCode(null)
		const {name, value} = e.target
		const formObjClone = {...formObj}
		setErrMsg(false)

		if (name === 'messageComment') {
			if (/^Bizsaya\s/i.test(value)) {
				return setErrMsg(true)
			}
		}

		formObjClone[name] = value
		setFormObj(formObjClone)
	}

	return (
		<Box>

			<Alert severity={'info'}>
				Sila masukkan mesej yang akan dihantar ke prospek anda.
				Anda boleh biarkan salah satu mesej kosong. Tetapi tidak boleh membiarkan kedua-duanya kosong.
				<br />
				<strong>Mesej ke komen</strong> adalah mesej yang akan dibalas ke Komen Post yang diberikan komen oleh pemberi komen.
				Mesej tersebut boleh dibaca oleh prospek lain.
				<br />
				<strong>Mesej ke inbox</strong> adalah mesej yang akan dibalas ke Inbox Facebook pemberi komen.
				Mesej tersebut hanya boleh dibaca oleh pemberi komen sahaja.
				<br />
				Gunakan kod <code>@sendername</code> untuk mention nama prospek didalam mesej yang akan diberikan.
				Kod ini boleh digunakan pada mana-mana mesej
			</Alert>

			<StyledDiv>

				{
					errCode === 2  && (
						<Alert className={'alert'} severity={'error'}>
							Sama ada mesej ke komen atau mesej ke inbox atau sekurangnya 1 gambar adalah diperlukan
						</Alert>
					)
				}

				<TextField
					fullWidth
					multiline
					label="Mesej ke komen"
					variant="outlined"
					margin="normal"
					rows={4}
					name={'messageComment'}
					InputLabelProps={{shrink: true}}
					value={formObj.messageComment}
					onChange={onChangeMessage}
					onClick={() => setErrCode(null)}
					error={errMsg}
					helperText={errMsg? 'Mesej auto reply ke komen tidak boleh mula dengan Bizsaya' : ''}
				/>

				<TextField
					fullWidth
					multiline
					label="Mesej ke inbox"
					variant="outlined"
					margin="normal"
					rows={4}
					name={'messageInbox'}
					InputLabelProps={{shrink: true}}
					value={formObj.messageInbox}
					onChange={onChangeMessage}
					onClick={() => setErrCode(null)}
				/>
			</StyledDiv>
		</Box>
	)
}
