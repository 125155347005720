import React from 'react'
import {connect} from 'react-redux'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'

import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import {SET_MISC} from '../../../constant'

const StyledBox = styled(Box)`
  ${({theme}) => `
    a {
			text-decoration: none;
			color: ${theme.palette.info.main};
		}
  `}
`

const InnerTabNotification = function InnerTabNotification(props) {

	const {formObj, save, setSnackbarMsg} = props
	
	function removeNotification() {
		save(true)
	}

	return (
		<StyledBox>
			{
				_.isNull(formObj.tId) && (
					<>
						<Alert severity={'info'}>
							Salin kod arahan dibawah dan tampal kod arahan tersebut ke&nbsp;
							<a href="https://t.me/Bizsaya_bot" target="_blank" rel="noreferrer">BOT Telegram Bizsaya</a>.
							Anda juga boleh tampal kod arahan tersebut didalam
							group Telegram perniagaan anda. Pastikan anda telah add BOT Telegram Bizsaya terlebih dahulu ke group
							Telegram perniagaan anda.
							Notifikasi akan ditetapkan untuk {formObj.pageId === 0? 'semua Facebook Page' : 'Facebook Page ini'}
						</Alert>

						<TextField
							sx={{mt: 3}}
							fullWidth
							value={formObj.code}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<CopyToClipboard text={formObj.code} onCopy={() => setSnackbarMsg('Kod arahan telah disalin')}>
											<Tooltip title={'Salin kod arahan secara automatik'}>
												<IconButton edge="end">
													<FileCopyOutlinedIcon/>
												</IconButton>
											</Tooltip>
										</CopyToClipboard>
									</InputAdornment>
								)
							}}
							label="Kod arahan"
						/>
					</>
				)
			}

			{
				!_.isNull(formObj.tId) && (
					<>
						<Alert severity={'info'}>
							Klik BATALKAN SAMBUNGAN NOTIFIKASI untuk membatalkan
							sambungan notifikasi nombor telefon
							untuk {formObj.pageId === 0? 'semua Facebook Page' : 'Facebook Page ini'}
						</Alert>

						<Button
							fullWidth
							sx={{mt: 3}}
							color={'error'}
							variant={'outlined'}
							onClick={removeNotification} >
							Batalkan sambungan notifikasi
						</Button>
					</>
				)
			}
		</StyledBox>
	)
}

const mapStateToProps = (state) => {
	return {
		prospects: state.prospects
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		setSnackbarMsg: (snackbarMessage = null) => {
			dispatch({ type: SET_MISC, payload: { snackbarMessage } })
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InnerTabNotification)