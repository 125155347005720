import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'

import {styled} from '@mui/material/styles'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'

import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'

const StyledDiv = styled(Box)`
  ${({ theme }) => `
    padding: ${theme.spacing(2)};
    
    .retrybox {
      display: flex;
      justify-content: flex-end;
      margin-bottom: ${theme.spacing(2)};
      
      > button:first-of-type {
        margin-right: ${theme.spacing(1)};
      }
    }
  `}
`

const ListPages = (props) => {
  const { pages, onOtherAccountPages, history, location, begin, confirmPage } = props

  const _MAX_PAGE_LIMIT = 2

  const [activePage, setActivePage] = useState([])
  const [overlimit, setOverlimit] = useState(false)

  useEffect(() => {
    history.push(location.pathname)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setOverlimit(activePage.length >= _MAX_PAGE_LIMIT)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage])

  function addPageToActive (page) {
    const activePageClone = _.cloneDeep(activePage)

    const pageIndex = activePageClone.indexOf(page.id)
    const otherOwnerIndex = _.findIndex(onOtherAccountPages, { fbId: page.id })
    if (pageIndex === -1) {
      if (overlimit || otherOwnerIndex !== -1) {
        return
      }

      activePageClone.push(page.id)
    } else {
      activePageClone.splice(pageIndex, 1)
    }

    setActivePage(activePageClone)
  }

  return (
    <>

      <StyledDiv>
        <Box className='retrybox'>
          <ButtonGroup>
            <Button
              color={'secondary'}
              variant='outlined'
              startIcon={<AutorenewOutlinedIcon />}
              onClick={begin}>
              Ulang semula
            </Button>
            <Button
              color='primary'
              variant='outlined'
              startIcon={<SaveOutlinedIcon />}
              onClick={() => confirmPage(activePage)}
              disabled={_.isEmpty(activePage)}
            >
              Simpan
            </Button>
          </ButtonGroup>
        </Box>

        <Box className='infoBox'>
          <Alert severity={'info'}>
            Sila pilih Facebook Page yang akan digunakan. Maksimum {_MAX_PAGE_LIMIT} Facebook Page yang dibenarkan didalam tempoh percubaan
          </Alert>
          {
            !_.isEmpty(onOtherAccountPages) && (
              <Alert severity={'warning'}>
                Terdapat Facebook Page yang telah diurus oleh akaun yang lain, Sekiranya anda ingin menguruskan Facebook Page tersebut, Sila maklum kepada admin
              </Alert>
            )
          }
        </Box>
      </StyledDiv>

      <List>
        {
          pages.map(page => {
            return (
              <ListItem
                key={page.id}
                button
                divider
                onClick={() => addPageToActive(page)}
              >
                <ListItemAvatar>
                  <Avatar src={`https://graph.facebook.com/v2.10/${page.id}/picture`} />
                </ListItemAvatar>
                <ListItemText
                  primary={page.name}
                  secondary={
                    _.isEmpty(_.find(onOtherAccountPages, { fbId: page.id })) ? '' : 'FB Page ini tidak boleh diaktifkan kerana telah diuruskan oleh akaun Bizsaya yang lain'
                  }
                  secondaryTypographyProps={{ variant: 'caption' }}
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge='end'
                    onChange={() => addPageToActive(page)}
                    checked={activePage.indexOf(page.id) !== -1}
                    disabled={(activePage.indexOf(page.id) === -1 && overlimit) || !_.isEmpty(_.find(onOtherAccountPages, { fbId: page.id }))}
                    inputProps={{ 'aria-labelledby': page.name }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            )
          })
        }
      </List>
    </>
  )
}

export default withRouter(ListPages)
