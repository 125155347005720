import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import {connect} from 'react-redux'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'

import TabHeading from '../../components/tab-heading'
import HeadingBtn from './heading-btn'
import Begin from './begin'
import Done from './done'

import {SET_MISC} from '../../../constant'
import {updateProfile} from '../../../actions/profile'

const StyledBox = styled(Box)`
  .heading {
    height: auto;
  }
`

const StyledInnerBox = styled(Box)`
  ${({theme}) => `    
    padding: ${theme.spacing(2)};
    
    a {
			text-decoration: none;
			color: ${theme.palette.info.main};
		}
  `}
`

const NotificationSetting = function NotificationSetting(props) {
	const {account, setSnackbarMsg, updateProfile} = props
	const {tId, notification: accNotification} = account

	const [code, setCode] = useState('')
	const [notification, setNotification] = useState({
		error: true,
		expired_3_day: true,
		expired_today: true,
		invalid: true
	})

	useEffect(() => {
		setCode(`/Bizsaya set administration ${account.fbId}`)

		setNotification({...accNotification})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	function removeExistingTid() {
		account.tId = null

		updateProfile(account)
			.then(() => setSnackbarMsg('Tetapan telegram telah dibatalkan'))
			.catch(() => { })
	}

	function updateNotification() {
		account.notification = {...notification}

		updateProfile(account)
			.then(() => setSnackbarMsg('Akaun telah dikemaskini'))
			.catch(() => { })
	}

	return (
		<StyledBox>
			<TabHeading
				className="heading"
				title="Tetapan Notifikasi"
				description="Tetapan notifikasi sistem seperti akaun akan tamat tempoh atau ralat yang akan menyebabkan ganguan pada akaun anda"
				titleProps={{
					sm: 12,
					md: !_.isNull(tId) ? 7 : 12,
					lg: !_.isNull(tId) ? 8 : 12,
				}}
				btnContainerProps={{
					sm: 12,
					md: !_.isNull(tId) ? 5 : false,
					lg: !_.isNull(tId) ? 4 : false
				}}
			>
				{
					!_.isNull(tId) && <HeadingBtn removeExistingTid={removeExistingTid} updateNotification={updateNotification} />
				}
			</TabHeading>

			<StyledInnerBox>
				{
					_.isNull(tId) ? <Begin code={code}/> : <Done setNotification={setNotification} />
				}
			</StyledInnerBox>
		</StyledBox>
	)
}

const mapStateToProps = (state) => {
	return {
		account: { ...state.account.detail }
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		setSnackbarMsg: (snackbarMessage = null) => {
			dispatch({ type: SET_MISC, payload: { snackbarMessage } })
		},
		updateProfile: account => {
			return dispatch(updateProfile(account))
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NotificationSetting)