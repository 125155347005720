import React from 'react'

import Alert from '@mui/material/Alert'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'


export default function Paper3(props) {

	return (
		<Paper sx={{padding: 2}}>
			<Alert severity={'info'}>
				Fungsi tambahan notifikasi proxy adalah salah satu cara paling pantas untuk anda mengetahui
				ada komen baru di Facebook page anda. Dengan ini, Anda tidak akan terlepas notifikasi facebook.
				Untuk menggunakan fungsi ini. Sila maklumkan kepada admin Bizsaya. *Terdapat tambahan pembayaran.
			</Alert>

			<TextField
				fullWidth
				margin={'normal'}
				label='Telegram token'
				defaultValue='596664444:AAGbOhu6oAbhHTa01yDTo7Dy6lJ9Zzzu_1w'
				variant='outlined'
				InputProps={{ readOnly: true }}
				InputLabelProps={{ shrink: true }}
				helperText='Sila maklumkan kepada Admin Bizsaya sekiranya anda ingin gunakan fungsi ini'
			/>

		</Paper>
	)
}