import React, {useEffect, useState} from 'react'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'

const StyledDiv = styled(Box)`
  ${({theme}) => `
    padding: ${theme.spacing(2)};
  `}
`

export default function SubTab4(props) {

	const {formObj, setFormObj, errCode, setErrCode} = props

	const [disableTop, setDisableTop] = useState(false)

	useEffect(() => {
		const index = formObj.keywords.indexOf('*')
		if (index !== -1) {
			const formObjClone = _.cloneDeep(formObj)
			formObjClone.top = false
			setFormObj(formObjClone)

			setDisableTop(true)
		} else {
			setDisableTop(false)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formObj.keywords])

	function changeLabel(e) {
		let {value} = e.target
		value = value.replace(/\s/g, '_').replace(/[^_A-Za-z0-9]/g, '')

		const formObjClone = _.cloneDeep(formObj)
		formObjClone.label = value

		setFormObj(formObjClone)
	}

	function changeSwitch(e) {
		const {checked, name} = e.target
		const formObjClone = _.cloneDeep(formObj)

		formObjClone[name] = checked

		setFormObj(formObjClone)
	}

	return (
		<Box>
			<Alert severity={'info'}>
				Beberapa tetapan tambahan yang anda boleh masukkan. <br/>
				<strong>Label</strong> adalah label yang anda ingin letak pada tetapan ini supaya memudahkan rujukan anda semula
				nanti.
				Tick pada <i>Auto like komen</i> untuk Bizsaya like komen yang diberikan oleh prospek dan tetapan untuk reply
				komen level pertama.
			</Alert>

			<StyledDiv>

				<TextField
					fullWidth
					variant="outlined"
					className="label-field"
					label="Label"
					margin="normal"
					helperText={errCode === 4 ? 'Label ini telah digunakan untuk tetapan auto reply lain' : errCode === 3 ? 'Label untuk tetapan auto reply ini diperlukan' : 'Label untuk tetapan auto reply ini'}
					InputLabelProps={{shrink: true}}
					value={formObj.label}
					onChange={changeLabel}
					error={errCode === 3 || errCode === 4}
					onFocus={() => setErrCode(null)}
				/>

				<Box>
					<FormControlLabel
						control={<Switch checked={formObj.like} onChange={changeSwitch} name="like" color="primary"/>}
						label="Auto like komen yang diberikan"
					/>
				</Box>

				<Box>
					<FormControlLabel
						control={
							<Switch checked={formObj.top} onChange={changeSwitch} name="top" color="primary" disabled={disableTop}/>
						}
						label={'Auto reply komen yang diberikan pada komen yang di "level" pertama sahaja'}
					/>
				</Box>

			</StyledDiv>
		</Box>
	)
}
