import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {withRouter, Link} from 'react-router-dom'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'

import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import menuList from './menu-list'
import MenuIcon from './menu-icon'

const StyledDrawer = styled(Drawer)`
	${({theme}) => `
		@media (max-width: ${theme.breakpoints.values.md}px) {
      display: none;
    }
    
    > div {
			width: inherit;
		}
	`}
`

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 40px;
`

const StyledBox = styled(Box)`
	${({theme}) => `
		overflow: auto;
				
		a {
			text-decoration: none;
			color: ${theme.palette.text.primary}
		}
	`}
`

const DrawerV2 = function DrawerV2(props) {

	const {location} = props

	const [menus, setMenus] = useState([])

	useEffect(() => {
		defineActive()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		defineActive()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname])

	function defineActive () {
		const menuListClone = JSON.parse(JSON.stringify(menuList))

		menuListClone.map(menu => {
			menu.active = false
			if (menu.to === '/') {
				if (location.pathname === '/') {
					menu.active = true
				}
			} else {
				const reg = new RegExp(`^\\${menu.to}`)
				menu.active = !_.isNull(location.pathname.match(reg))
			}

			return menu
		})

		setMenus(menuListClone)
	}
	
	return (
		<StyledDrawer variant="permanent" sx={{width: 190}} >
			<Toolbar />
			<StyledBox>
				<List>
					{
						menus.map((menu, index) => {
							return (
								<Link key={index} to={menu.to}>
									<ListItem selected={menu.active} button key={index}>
										<StyledListItemIcon><MenuIcon to={menu.to} /></StyledListItemIcon>
										<ListItemText primary={menu.label} />
									</ListItem>
								</Link>
							)
						})
					}
				</List>
			</StyledBox>
		</StyledDrawer>
	)
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(DrawerV2))