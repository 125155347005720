import React from 'react'
import {connect} from 'react-redux'
import dayjs from 'dayjs'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'

import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined'

const TitleBox = styled(Box)`
  ${({theme}) => `
    display: flex;
  	justify-content: flex-start;
  	align-items: center;
  	
  	h5 {
  		margin-bottom: 0px;
  		margin-left: ${theme.spacing(1)};
  	}
  `}
`

const PageListing = function PageListing(props) {

	const {fbPages, history} = props

	return (
		<Card variant={'outlined'}>
			<CardContent>
				<Grid container>
					<Grid item xs>
						<TitleBox className="title">
							<FacebookOutlinedIcon/>
							<Typography gutterBottom variant="h5">Facebook Pages</Typography>
						</TitleBox>
					</Grid>
				</Grid>
			</CardContent>
			<List>
				{
					_.filter(fbPages, {isEnable: true}).map(fbPage => {

						let secondary = `Tamat akses ${dayjs(fbPage.expiredAt).format('DD-MM-YYYY')}`
						let color = 'primary'

						if (!fbPage.isConnected && fbPage.isActive) {
							color = 'error'
							secondary += ` - FACEBOOK PAGE INI TERPUTUS AKSES`
						}

						if (!fbPage.isActive) {
							color = 'secondary'
						}

						return (
							<ListItem key={fbPage.id} divider>
								<ListItemAvatar>
									<Avatar src={`https://graph.facebook.com/v2.10/${fbPage.fbId}/picture`}/>
								</ListItemAvatar>
								<ListItemText
									primary={fbPage.name}
									secondary={secondary}
									secondaryTypographyProps={{color}}
								/>
							</ListItem>
						)
					})
				}
			</List>
			<CardActions>
				<Button onClick={() => history.push('/config?tab=2')}>Kemaskini</Button>
				<Button onClick={() => history.push('/config?tab=2')}>Pembayaran</Button>
			</CardActions>
		</Card>
	)
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PageListing)