import _ from 'lodash'
import requester from './requester'
import {
  SET_PROSPECTS,
  ADD_WHATSAPP_LINK,
  UPDATE_WHATSAPP_LINK,
  DELETE_WHATSAPP_LINK
} from '../constant'

export const getProspect = () => dispatch => {
  return requester.get('/prospects')
    .then(response => {
      response.data.configuration = [...response.data.configuration].map(c => {
        if (_.isNull(c.message)) {
          c.message = ''
        }
        if (_.isNull(c.whatsappMsg)) {
          c.whatsappMsg = ''
        }
        return c
      })

      response.data.whatsappLinks.map(ws => {
        ws.agents = _.sortBy(ws.Agents, 'order', 'asc')
        ws.agents.map(a => {
          a.number = a.number.replace(/601/, '')
          return a
        })
        delete ws.Agents
        return ws
      })

      response.data.contactNumbers = _.orderBy(response.data.contactNumbers, ['createdAt'], ['desc'])

      dispatch({ type: SET_PROSPECTS, payload: response.data })
    })
}

export const updateProspect = form => dispatch => {
  return requester.put('/prospects', form)
    .then(response => {
      dispatch({ type: SET_PROSPECTS, payload: { configuration: response.data } })
    })
}

export const createNewWhatsappLink = form => dispatch => {
  return requester.post('/whatsapp-links', form)
    .then(response => {
      response.data.agents = _.sortBy(response.data.Agents, 'order', 'asc')
      response.data.agents.map(a => {
        a.number = a.number.replace(/601/, '')
        return a
      })
      delete response.data.Agents
      dispatch({ type: ADD_WHATSAPP_LINK, payload: response.data })
    })
}

export const updateWhatsappLink = form => dispatch => {
  return requester.put(`/whatsapp-links/${form.id}`, form)
    .then(response => {
      response.data.agents = _.sortBy(response.data.Agents, 'order', 'asc')
      response.data.agents.map(a => {
        a.number = a.number.replace(/601/, '')
        return a
      })
      delete response.data.Agents
      dispatch({ type: UPDATE_WHATSAPP_LINK, payload: response.data })
    })
}

export const deleteWhatsappLink = id => dispatch => {
  return requester.delete(`/whatsapp-links/${id}`)
    .then(() => {
      dispatch({ type: DELETE_WHATSAPP_LINK, payload: id })
    })
}

export const getNumbers = () => dispatch => {
  return requester.get('/numbers')
    .then(response => {
      dispatch({ type: SET_PROSPECTS, payload: { contactNumbers: response.data } })
    })
}
