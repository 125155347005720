export const SET_ACCOUNT = 'SET_ACCOUNT'
export const SET_FACEBOOK = 'SET_FACEBOOK'
export const SET_PROSPECTS = 'SET_PROSPECTS'
export const SET_TOKEN_EXPIRE_AT = 'SET_TOKEN_EXPIRE_AT'
export const SET_ACCOUNT_ERROR_CODE = 'SET_ACCOUNT_ERROR_CODE'
export const SET_MISC = 'SET_MISC'
export const SET_ACTIVE_FB_PAGE = 'SET_ACTIVE_FB_PAGE'
export const ADD_NEW_AUTO_REPLY = 'ADD_NEW_AUTO_REPLY'
export const UPDATE_AUTO_REPLY = 'UPDATE_AUTO_REPLY'
export const DELETE_AUTO_REPLY = 'DELETE_AUTO_REPLY'
export const SET_FACEBOOK_UPLOAD_OPTION = 'SET_FACEBOOK_UPLOAD_OPTION'
export const ADD_WHATSAPP_LINK = 'ADD_WHATSAPP_LINK'
export const UPDATE_WHATSAPP_LINK = 'UPDATE_WHATSAPP_LINK'
export const DELETE_WHATSAPP_LINK = 'DELETE_WHATSAPP_LINK'
export const ADD_N2WL = 'ADD_N2WL'
export const UPDATE_N2WL = 'UPDATE_N2WL'
export const DELETE_N2WL = 'DELETE_N2WL'
export const ADD_NUMBER = 'ADD_NUMBER'
export const SET_INSTAGRAM = 'SET_INSTAGRAM'
export const SET_ACTIVE_IG = 'SET_ACTIVE_IG'
export const ADD_NEW_IG_AUTO_REPLY = 'ADD_NEW_IG_AUTO_REPLY'
export const UPDATE_IG_AUTO_REPLY = 'UPDATE_IG_AUTO_REPLY'
export const DELETE_IG_AUTO_REPLY = 'DELETE_IG_AUTO_REPLY'
export const SET_IG_UPLOAD_OPTION = 'SET_IG_UPLOAD_OPTION'
export const SET_ADMIN_ACCOUNTS = 'SET_ADMIN_ACCOUNTS'
export const UPDATE_ADMIN_ACCOUNT = 'UPDATE_ADMIN_ACCOUNT'
export const SET_ADMIN_PAGES = 'SET_ADMIN_PAGES'
export const UPDATE_ADMIN_PAGE = 'UPDATE_ADMIN_PAGE'
export const DELETE_ADMIN_PAGE = 'DELETE_ADMIN_PAGE'
export const SET_ADMIN_INSTAGRAMS = 'SET_ADMIN_INSTAGRAMS'
export const UPDATE_ADMIN_INSTAGRAM = 'UPDATE_ADMIN_INSTAGRAM'
export const DELETE_ADMIN_INSTAGRAM = 'DELETE_ADMIN_INSTAGRAM'
export const SET_PHOTOS = 'SET_PHOTOS'
export const ADD_PHOTOS = 'ADD_PHOTOS'
export const DELETE_PHOTO = 'DELETE_PHOTO'
export const PHOTO_UPLOAD_OPTION = 'PHOTO_UPLOAD_OPTION'
export const SET_HOMEPAGE = 'SET_HOMEPAGE'
export const SET_DEBUG = 'SET_DEBUG'
export const SET_FB_PAGE_FROM_FB = 'SET_FB_PAGE_FROM_FB'
export const SET_IG_FROM_FB = 'SET_IG_FROM_FB'
