export default (theme) => `
  .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    &>svg {
      margin-right: ${theme.spacing(1)};
    }
    
    &>h5 {
      margin-bottom: 0px;
    }
  }
  
  .content {
    margin-left: ${theme.spacing(3)};
    
    @media (max-width: ${theme.breakpoints.values.sm}px) {
      margin-left: 0px;      
    }
  }
  
  .pointer {
    display: flex;
    
    @media (max-width: ${theme.breakpoints.values.sm}px) {
      display: block;
    }
    
    &>p {
      
      &:first-of-type {
        flex: 0 0 auto;
      }
      
      &:last-of-type {
        flex: 0 0 65%;
      }
      
      @media (max-width: ${theme.breakpoints.values.sm}px) {
        &:first-of-type {
          font-weight: bolder;
        }
        
        &:last-of-type {
          padding-left: 0px;
          margin-bottom: ${theme.spacing(1)};
        }
      }
      
    }
    
    &>div {
      flex: 1 1 auto;
      border-bottom: 1px dotted rgba(0, 0, 0, 0.54);
      height: 10px;
      margin: 0px ${theme.spacing(1)};
      
      @media (max-width: ${theme.breakpoints.values.sm}px) {
        display: none;
      }
    }
  }
`
