import React, {useEffect, useState} from 'react'
import _ from 'lodash'

import {useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

export default function AgentDialog(props) {

	const {agent, setAgent, save} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const [disabled, setDisabled] = useState(true)

	useEffect(() => {

		let dis = false
		if (_.isEmpty(_.trim(agent.name))) {
			dis = true
		}
		if (_.isEmpty(_.trim(agent.number))) {
			dis = true
		}

		setDisabled(dis)


	}, [agent.name, agent.number])

	function update (e) {
		const {name, value} = e.target
		const agentClone = {...agent}

		agentClone[name] = value
		setAgent(agentClone)
	}

	return (
		<Dialog open={true} onClose={() => setAgent({})} maxWidth={'sm'} fullScreen={isMobile}>
			<DialogTitle>{agent.isNew? 'Tambah' : 'Kemaskini'} agen tetapan pautan whatsapp</DialogTitle>
			<DialogContent>
				<Alert severity={'info'}>
					Nama dan nombor telefon adalah diperlukan.
					Pastikan nombor telefon tersebut <i>reachable</i> melalui whatsapp
				</Alert>

				<TextField
					fullWidth
					label="Name"
					variant="outlined"
					margin="normal"
					name={'name'}
					value={agent.name}
					inputProps={{ maxLength: 255 }}
					onChange={update}
					autoComplete={'off'}
					InputLabelProps={{ shrink: true }}/>

				<TextField
					fullWidth
					label="Nombor telefon"
					variant="outlined"
					margin="normal"
					name={'number'}
					value={agent.number}
					onChange={update}
					inputProps={{ maxLength: 9, inputMode: 'numeric', pattern: '[0-9]*' }}
					autoComplete={'off'}
					InputProps={{
						startAdornment: <InputAdornment position="start">+601</InputAdornment>,
					}}
					InputLabelProps={{ shrink: true }}/>

				<TextField
					fullWidth
					multiline
					label="Mesej spesifik"
					variant="outlined"
					margin="normal"
					rows={4}
					name={'message'}
					value={agent.message}
					onChange={update}
					helperText={'Biarkan kosong sekiranya anda ingin gunakan mesej yang anda telah tetapkan diutama'}
					InputLabelProps={{ shrink: true }}/>

			</DialogContent>
			<DialogActions>
				<Button variant={'outlined'} color={'warning'} onClick={() => setAgent({})} fullWidth={isMobile}>
					Batal
				</Button>
				<Button variant={'outlined'} onClick={save} fullWidth={isMobile} disabled={disabled}>
					{agent.isNew? 'Tambah' : 'Kemaskini'}
				</Button>
			</DialogActions>
		</Dialog>
	)
}
