import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Switch from '@mui/material/Switch'

const StyledBox = styled(Box)`
  ${({theme}) => `
    
  `}
`

const Done = function Done(props) {

	const {account: {notification: noti}, setNotification} = props

	const [expired3, setExpired3] = useState(false)
	const [expiredToday, setExpiredToday] = useState(false)
	const [error, setError] = useState(false)
	const [invalid, setInvalid] = useState(false)

	useEffect(() => {

		setExpired3(noti.expired_3_day)
		setExpiredToday(noti.expired_today)
		setError(noti.error)
		setInvalid(noti.invalid)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [noti.error, noti.expired_3_day, noti.expired_today, noti.invalid])

	useEffect(() => {

		setNotification({ error, expired_3_day: expired3, expired_today: expiredToday, invalid })

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [expired3, expiredToday, error, invalid])

	return (
		<StyledBox>
			<Alert severity={'info'}>
				Tetapan telegram akaun telah ditetapkan untuk akaun anda. Batal tetapan yang ada sekarang sekiranya
				anda ingin kemaskini tetapan baru.
				Pilih notifikasi yang anda ingin terima dibawah
			</Alert>

			<List>
				<ListItem>
					<ListItemIcon>
						<Switch
							edge="start"
							onChange={(e,v) => setExpired3(v)}
							checked={expired3}
						/>
					</ListItemIcon>
					<ListItemText primary="Akaun akan tamat dalam tempoh 3 hari lagi" />
				</ListItem>
				<ListItem>
					<ListItemIcon>
						<Switch
							edge="start"
							onChange={(e,v) => setExpiredToday(v)}
							checked={expiredToday}
						/>
					</ListItemIcon>
					<ListItemText primary="Akaun akan tamat pada hari ini" />
				</ListItem>
				<ListItem>
					<ListItemIcon>
						<Switch
							edge="start"
							onChange={(e,v) => setInvalid(v)}
							checked={invalid}
						/>
					</ListItemIcon>
					<ListItemText primary="Masalah akses token pada akaun" />
				</ListItem>
				<ListItem>
					<ListItemIcon>
						<Switch
							edge="start"
							onChange={(e,v) => setError(v)}
							checked={error}
						/>
					</ListItemIcon>
					<ListItemText primary="Ralat pada facebook yang menyebabkan auto reply gagal dibalas" />
				</ListItem>
			</List>
		</StyledBox>
	)
}

const mapStateToProps = (state) => {
	return {
		account: state.account.detail
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Done)