import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'

import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Alert from '@mui/material/Alert'

import InnerTabConfig from './inner-tab-config'
import InnerTabMessage from './inner-tab-message'
import InnerTabNotification from './inner-tab-notification'

const StyledBox = styled(Box)`
  ${({theme}) => `
    padding: 0px ${theme.spacing(2)};
  `}
`

const FormControlStyled = styled(FormControl)`
  ${({theme}) => `
		margin-top: ${theme.spacing(1)};		
	`}
`
const TabBoxStyled = styled(Box)`
  ${({theme}) => `
		padding-top: ${theme.spacing(2)};
		border-bottom: 1px dotted ${theme.palette.divider};
	`}
`

const StyledBoxTab = styled(Box)`
  ${({theme}) => `
    padding: ${theme.spacing(2)};
  `}
`

const FacebookPages = function FacebookPages(props) {

	const {account, confData, setConfData, save} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const [activeIg, setActiveIg] = useState(0)
	const [tabIndex, setTabIndex] = useState(0)
	const [activeIgs, setActiveIgs] = useState([])
	const [tCode, setTCode] = useState('')

	useEffect(() => {

		const activeInstagram = _.filter(account.InstagramInsta, {isConnected: true})

		if (activeInstagram.length === 1) {
			setActiveIg(activeInstagram[0].id)
			setTCode(`/Bizsaya link IG ${account.fbId}`)
		} else {
			// const pageDetail = _.find(activeInstagram, {id: activeIg})
			// console.log(pageDetail)
		}

		setActiveIgs(activeInstagram)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account.InstagramInsta, activeIg])

	return (
		<Box>
			{
				_.isEmpty(activeIgs) ? (
					<Alert severity={'warning'}>
						Tiada instagram yang aktif untuk tetapan prospek
					</Alert>
				) : (
					<>
						<Alert severity={'info'}>
							Sila pilih instagram yang ingin ditetapkan atau pilih untuk tetapkan pada semua Instagram
						</Alert>

						<StyledBox>
							<FormControlStyled fullWidth>
								<Select
									displayEmpty
									value={activeIg}
									onChange={e => setActiveIg(e.target.value)}
									size={'small'}
								>
									<MenuItem value={0} disabled={activeIgs.length === 1}>Semua Instagram</MenuItem>
									{
										activeIgs.map(ig => {
											return (
												<MenuItem key={ig.id} value={ig.id}>{ig.igName}</MenuItem>
											)
										})
									}
								</Select>
							</FormControlStyled>
						</StyledBox>

						<TabBoxStyled>
							<Tabs
								value={tabIndex}
								onChange={(e, v) => setTabIndex(v)}
								indicatorColor="primary"
								textColor="primary"
								variant={isMobile ? 'scrollable' : 'fullWidth'}
								aria-label="full width tabs example">
								<Tab label="Tetapan"/>
								<Tab label="Notifikasi"/>
								<Tab label="Mesej"/>
							</Tabs>
						</TabBoxStyled>
						<StyledBoxTab>
							{
								tabIndex === 0 && <InnerTabConfig confData={confData} setConfData={setConfData} activeIg={activeIg}/>
							}
							{
								tabIndex === 1 && (
									<InnerTabNotification
										confData={confData}
										activeIg={activeIg}
										code={tCode}
										save={save} />
								)
							}
							{
								tabIndex === 2 && <InnerTabMessage confData={confData} setConfData={setConfData} activeIg={activeIg}/>
							}
						</StyledBoxTab>
					</>
				)
			}
		</Box>
	)
}

const mapStateToProps = (state) => {
	console.log(state.prospects)
	return {
		prospects: state.prospects,
		account: state.account.detail
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FacebookPages)