import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'

import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import Heading from './heading'
import tabWrapperStyle from '../../../styles/tab-wrapper'

import Listing from './listing'
import SubTab1 from './sub-tab1'
import SubTab2 from './sub-tab2'
import SubTab3 from './sub-tab3'
import SubTab4 from './sub-tab4'
import SubTab5 from './sub-tab5'
import {SET_MISC} from '../../../constant'
import {createAutoReply, deleteAutoReply, updateAutoReply, updateFacebookDetail} from '../../../actions/facebook'
import {getUploadPhotoUrl} from '../../../actions/photos'

const StyledBox = styled(Box)`
  ${({theme}) => `  
    ${tabWrapperStyle(theme)}
  `}
`

const __DEFAULT = {
	id: null,
	editId: null,
	keywords: ['pm', 'nak', 'nk', 'harga', 'harge', 'hrga', 'hrg', 'price', 'berape', 'berapa', 'brape', 'brp', 'brapa'],
	messageInbox: '',
	messageComment: '',
	photos: [],
	toDeletePhotos: [],
	label: '',
	like: true,
	top: false,
	specificId: [],
	toDeleteSpecific: []
}

const Tab1 = function Tab1(props) {

	const {
		autoReplies,
		activePage,
		setLoading,
		setSnackbarMsg,
		createAutoReply,
		updateAutoReply,
		deleteAutoReply,
		photos,
		getUploadPhotoUrl
	} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const [tabIndex, setTabIndex] = useState(0)
	const [openForm, setOpenForm] = useState(false)
	const [formObj, setFormObj] = useState(_.cloneDeep(__DEFAULT))
	const [errCode, setErrCode] = useState(null)
	const [uploadError, setUploadError] = useState(null)
	
	useEffect(() => {
		if (openForm) {
			const autoReply = _.find(autoReplies, {id: formObj.id})
			if(autoReply) {
				const formObjClone = _.cloneDeep(formObj)
				formObjClone.specificId = JSON.parse(JSON.stringify(autoReply.specificId))
				setFormObj(formObjClone)
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [autoReplies])

	useEffect(() => {

		if (!_.isUndefined(activePage.id)) {
			setOpenForm(false)
		}

	}, [activePage.id])

	useEffect(() => {

		if (_.isEmpty(photos.uploadOption)) {
			getUploadPhotoUrl()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [photos.uploadOption])

	useEffect(() => {
		if (openForm === false) {
			setTabIndex(0)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openForm])

	function createNew() {
		setOpenForm(true)
		const form = _.cloneDeep(__DEFAULT)

		form.label = `umum${autoReplies.length + 1}`

		setFormObj(form)
	}

	function save() {
		setErrCode(null)

		if (_.isEmpty(formObj.keywords)) {
			setTabIndex(0)
			return setErrCode(1)
		}
		if (_.isEmpty(_.trim(formObj.messageInbox)) && _.isEmpty(_.trim(formObj.messageComment))) {
			if (_.isEmpty(formObj.photos)) {
				setTabIndex(1)
				return setErrCode(2)
			}
			
			const photosClone = JSON.parse(JSON.stringify(formObj.photos))
			photosClone.map(p => {
				p.toDelete = _.findIndex(formObj.toDeletePhotos, p) !== -1
				return p
			})

			const found = _.find(photosClone, {toDelete: false})
			if (_.isEmpty(found)) {
				setTabIndex(1)
				return setErrCode(2)
			}
			
		}

		const keywordsClone = JSON.parse(JSON.stringify(formObj.keywords))
		if (keywordsClone.join().length > 500) {
			setTabIndex(0)
			return setErrCode(5)
		}
		if (_.isEmpty(_.trim(formObj.label))) {
			setTabIndex(3)
			return setErrCode(3)
		}

		let haveExistingLabel = false
		const reglabel = new RegExp(`^${_.escapeRegExp(formObj.label)}$`, 'i')
		autoReplies.forEach(a => {
			if (reglabel.test(a.label)) {
				if (a.id !== formObj.id) {
					haveExistingLabel = true
				}
			}
		})
		if (haveExistingLabel) {
			setTabIndex(3)
			return setErrCode(4)
		}

		setLoading(true)
		if (_.isNull(formObj.editId)) {
			createAutoReply(formObj)
				.then(() => setSnackbarMsg('Tetapan auto reply baru berjaya dicipta'))
				.catch(err => console.error(err))
				.then(() => {
					setOpenForm(false)
					setLoading(false)
				})
		} else {
			const specificId = [...formObj.specificId].filter(id => formObj.toDeleteSpecific.indexOf(id) === -1)
			formObj.specificId = specificId

			updateAutoReply(formObj)
				.then(() => setSnackbarMsg('Tetapan auto reply berjaya dikemaskini'))
				.catch(err => console.error(err))
				.then(() => {
					setOpenForm(false)
					setLoading(false)
				})
		}
	}

	function editThis(autoReply) {
		const form = _.pick({...autoReply}, _.keys({...__DEFAULT}))
		form.editId = autoReply.id
		form.keywords = form.keywords.split(',')
		form.toDeletePhotos = []
		form.toDeleteSpecific = []

		setFormObj(form)
		setOpenForm(true)
	}

	function confirmDeletion(id) {
		setLoading(true)
		deleteAutoReply(id)
			.then(() => setSnackbarMsg('Tetapan auto reply berjaya dipadam'))
			.catch(err => console.log(err))
			.then(() => setLoading(false))
	}

	function uploadSuccess (filename) {
		const formObjClone = {...formObj}
		formObjClone.photos = JSON.parse(JSON.stringify(formObjClone.photos))

		formObjClone.photos.push(filename)

		setFormObj(formObjClone)
	}

	return (
		<StyledBox>

			<Heading
				tabIndex={tabIndex}
				openForm={openForm}
				setOpenForm={setOpenForm}
				uploadSuccess={uploadSuccess}
				createNew={createNew}
				save={save}
				setUploadError={setUploadError}/>

			{
				openForm ? (
					<Box className="tab-wrapper">
						<Tabs
							orientation={isMobile ? 'horizontal' : 'vertical'}
							variant="scrollable"
							value={tabIndex}
							onChange={(e, i) => setTabIndex(i)}
							aria-label="Configurations"
							className="tabs">
							<Tab label="Kata kunci"/>
							<Tab label="Mesej"/>
							<Tab label="Gambar"/>
							<Tab label="Tambahan"/>
							<Tab label="Post spesifik"/>
						</Tabs>
						{
							tabIndex === 0 && (
								<SubTab1 formObj={formObj} setFormObj={setFormObj} errCode={errCode} setErrCode={setErrCode}/>
							)
						}
						{
							tabIndex === 1 && (
								<SubTab2 formObj={formObj} setFormObj={setFormObj} errCode={errCode} setErrCode={setErrCode}/>
							)
						}
						{
							tabIndex === 2 && (
								<SubTab3 formObj={formObj} setFormObj={setFormObj} uploadOption={photos.uploadOption} uploadError={uploadError}/>
							)
						}
						{
							tabIndex === 3 && (
								<SubTab4 formObj={formObj} setFormObj={setFormObj} errCode={errCode} setErrCode={setErrCode}/>
							)
						}
						{
							tabIndex === 4 && (
								<SubTab5 formObj={formObj} setFormObj={setFormObj} activePage={activePage}/>
							)
						}
					</Box>
				) : (
					<Listing editThis={editThis} confirmDeletion={confirmDeletion}/>
				)
			}

		</StyledBox>
	)
}

const mapStateToProps = (state) => {
	return {
		photos: {...state.photos},
		...state.facebook
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		getUploadPhotoUrl: () => dispatch(getUploadPhotoUrl()),
		setLoading: (showLoading = false) => {
			dispatch({type: SET_MISC, payload: {showLoading}})
		},
		setSnackbarMsg: (snackbarMessage = null) => {
			dispatch({type: SET_MISC, payload: {snackbarMessage}})
		},
		updateFacebookDetail: form => {
			return dispatch(updateFacebookDetail(form))
		},
		createAutoReply: form => {
			return dispatch(createAutoReply(form))
		},
		updateAutoReply: form => {
			return dispatch(updateAutoReply(form))
		},
		deleteAutoReply: id => {
			return dispatch(deleteAutoReply(id))
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Tab1)