import React, {useState} from 'react'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import _ from 'lodash'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip'

const StyledDiv = styled(Box)`
  ${({theme}) => `
    padding: ${theme.spacing(2)};
  `}
`

const StyledChip = styled(Box)`
  ${({theme}) => `
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: -${theme.spacing(0.5)};
    
    > div {
    	margin: ${theme.spacing(0.5)};
    }
  `}
`

export default function SubTab1(props) {

	const {formObj, setFormObj, errCode, setErrCode} = props

	const [keyword, setKeyword] = useState('')

	function addKeyword(e) {
		const {value} = e.target
		const formObjClone = {...formObj}
		formObjClone.keywords = JSON.parse(JSON.stringify(formObj.keywords))

		setKeyword(value)

		if (!_.isNull(value.match(','))) {
			const keywordsWithComma = value.split(',')
			keywordsWithComma.forEach(keyword => {
				if ((!_.isEmpty(_.trim(keyword)) && _.trim(keyword).length >= 2) || keyword === '*') {
					if (keyword === '*') {
						formObjClone.keywords = ['*']
					} else {
						formObjClone.keywords.push(_.trim(keyword))

						const starIndex = _.indexOf(formObjClone.keywords, '*')
						if (starIndex !== -1) {
							formObjClone.keywords.splice(starIndex, 1)
						}
					}
				}
			})

			formObjClone.keywords = _.uniq(formObjClone.keywords)
			
			setFormObj(formObjClone)
			setKeyword('')
		}
	}

	function addKeywordEnter(e) {
		if (e.key === 'Enter') {
			const {value} = e.target
			const formObjClone = {...formObj}
			formObjClone.keywords = JSON.parse(JSON.stringify(formObj.keywords))
			if ((!_.isEmpty(_.trim(value)) && _.trim(value).length >= 2) || value === '*') {
				if (value === '*') {
					formObjClone.keywords = ['*']
				} else {
					formObjClone.keywords.push(_.trim(keyword))

					const starIndex = _.indexOf(formObjClone.keywords, '*')

					if (starIndex !== -1) {
						formObjClone.keywords.splice(starIndex, 1)
					}
				}

				formObjClone.keywords = _.uniq(formObjClone.keywords)

				setFormObj(formObjClone)
				return setKeyword('')
			}
		}
	}

	function deleteOne(index) {
		const formObjClone = {...formObj}
		formObjClone.keywords = JSON.parse(JSON.stringify(formObj.keywords))

		formObjClone.keywords.splice(index, 1)

		formObjClone.keywords = _.uniq(formObjClone.keywords)
		setFormObj(formObjClone)
	}

	let helperText = (
		<span>
			Klik <code>&quot;Enter&quot;</code> atau gunakan <code>,</code> untuk masukkan kata kunci. Minima 2 huruf
		</span>
	)

	if (errCode === 1) {
		helperText = 'Sekurangnya 1 kata kunci adalah diperlukan'
	} else if (errCode === 4) {
		helperText = 'Kata kunci terlalu banyak. Sila kurangkan sedikit'
	}

	return (
		<Box>
			<Alert severity={'info'}>
				Sila masukkan kata kekunci yang akan digunakan untuk Bizsaya cari didalam komen yang diberikan oleh prospek
				anda.
				Sekiranya terdapat kata kunci yang anda tetapkan didalam komen yang diterima.
				Bizsaya akan membalas komen tersebut secara automatik. Untuk carian yang lebih efektif,
				Sila gunakan kata kekunci yang bukan berbentuk perkataan.
				Sbg contoh, gunakan kata kekunci <code>&quot;pm&quot;</code> berbanding kata kekunci <code>&quot;pm
				saya&quot;</code>
			</Alert>

			<StyledDiv>
				<TextField
					fullWidth
					label="Kata kunci"
					variant="outlined"
					margin="normal"
					error={errCode === 1 || errCode === 5}
					helperText={helperText}
					InputLabelProps={{shrink: true}}
					value={keyword}
					onClick={() => setErrCode(null)}
					onChange={addKeyword}
					onKeyDown={addKeywordEnter}
				/>

				<StyledChip>
					{
						formObj.keywords.map((keyword, key) => {
							return (
								<Chip
									key={key}
									label={keyword}
									onDelete={() => deleteOne(key)}
									variant="outlined"
								/>
							)
						})
					}
				</StyledChip>
			</StyledDiv>
		</Box>
	)
}
