import React, {createRef, useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Link, withRouter} from 'react-router-dom'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import {getAuthLink} from '../../actions/facebook'

import menuList from './menu-list'
import MenuIcon from './menu-icon'
import Loading from './loading'

const StyledDrawer = styled(Drawer)`
	${({theme}) => `
		@media (min-width: ${theme.breakpoints.values.md}px) {
      display: none;
    }
    
    overflow: auto;
    
    a {
    	text-decoration: none;
    	color: ${theme.palette.text.primary};
    }
	`}
`

const StyledMenu = styled(Menu)`
	${({theme}) => `
		@media (max-width: ${theme.breakpoints.values.md}px) {
      display: none;
    }
	`}
`

const Appbar = function Appbar(props) {

	const {account, location, history, showLoading} = props

	const [menus, setMenus] = useState([])
	const [anchorEl, setAnchorEl] = useState(null)
	const [openDrawer, setOpenDrawer] = useState(false)

	useEffect(() => {
		defineActive()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		defineActive()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname])

	function defineActive () {
		const menuListClone = JSON.parse(JSON.stringify(menuList)).slice(4)

		menuListClone.map(menu => {
			menu.active = false
			if (menu.to === '/') {
				if (location.pathname === '/') {
					menu.active = true
				}
			} else {
				const reg = new RegExp(`^\\${menu.to}`)
				menu.active = !_.isNull(location.pathname.match(reg))
			}

			return menu
		})

		menuListClone.push({
			label: 'Log keluar',
			to: '/logout',
			active: false
		})

		setMenus(menuListClone)
	}

	function openCloseMenu (event = null) {
		setAnchorEl(event? event.currentTarget : null)
		setOpenDrawer(!_.isNull(event))
	}

	function logout () {
		history.push('/logout')
	}

	return (
		<>
			<AppBar position='fixed' color='default'>
				<Toolbar variant="dense">
					<IconButton edge="start" color="inherit" aria-label="menu" sx={{mr: 2}} disableRipple>
						<img src='https://static.bizsaya.com/file/bizsaya-public/logo_app.png' alt='Logo Bizsaya' />
					</IconButton>
					<Typography variant="h6" color="inherit" component="div" sx={{ flexGrow: 1 }}>
						Bizsaya
					</Typography>
					<Box>
						<IconButton
							onClick={openCloseMenu}
							color='inherit'
							ref={createRef()}
							disableRipple >
							<Avatar src={`https://graph.facebook.com/v2.10/${account.fbId}/picture`} alt='Gambar facebook' sx={{ width: 30, height: 30 }} />
						</IconButton>
						<StyledMenu
							id="menu-appbar"
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={Boolean(anchorEl)}
							onClose={() => openCloseMenu()}
						>
							<MenuItem onClick={logout}>Log keluar</MenuItem>
						</StyledMenu>
					</Box>
				</Toolbar>
			</AppBar>
			<StyledDrawer anchor={'top'} open={openDrawer} onClose={() => openCloseMenu()}>
				<List>
					{
						menus.map((menu, index) => {
							return (
								<Link key={index} to={menu.to}>
									<ListItem selected={menu.active} button key={index} onClick={() => openCloseMenu()}>
										<ListItemIcon><MenuIcon to={menu.to} /></ListItemIcon>
										<ListItemText primary={menu.label} />
									</ListItem>
								</Link>
							)
						})
					}
				</List>
			</StyledDrawer>
			{
				showLoading && (
					<Loading />
				)
			}
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		showLoading: state.misc.showLoading,
		account: state.account.detail
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		getAuthLink
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Appbar))