import React from 'react'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

const StyleBox = styled(Box)`
	${({theme}) => `
		height: 100vh;
		width: 100vw;
		position: fixed;
		z-index: 9999;
		padding-top: ${theme.mixins.toolbar['@media (min-width:0px) and (orientation: landscape)'].minHeight}px;
	`}
`

export default function Loading(props) {

	return (
		<StyleBox>
			<LinearProgress color={'secondary'} />
		</StyleBox>
	)
}