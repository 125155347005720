import React from 'react'

import TabHeading from '../../../components/tab-heading'

export default function Heading(props) {

	return (
		<TabHeading
			className="heading"
			title={'Fungsi tambahan'}
			description={'Fungsi-fungsi tambahan yang anda boleh gunakan untuk membantu proses automasi Facebook page anda. Info didalam kekotak akan menerangkan lebih lanjut fungsi tambahan tersebut'}
			titleProps={{ xs: 12 }}
			btnContainerProps={{ xs: 12 }}>
		</TabHeading>
	)
}