import _ from 'lodash'

export {
	parse,
	stringify
}

function parse (url) {
	const qs = {}
	url.split('&').forEach(q => {
		const e = q.split('=')
		qs[e[0]] = _.isEmpty(_.trim(e[1]))? '' : decodeURIComponent(e[1])
	})

	return qs
}

function stringify (qs) {
	const uri = []

	_.forEach(qs, (v, k) => {
		if (!_.isEmpty(_.trim(k))) {
			uri.push(`${k}=${encodeURIComponent(v)}`)
		}
	})

	return uri.join('&')
}