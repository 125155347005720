import React, {useState} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'
import Cookie from 'js-cookie'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'

import ChangeIgDialog from './change-ig-dialog'
import HeadingBtn from './btn-container'
import {SET_ACTIVE_IG} from '../../../constant'
import TabHeading from '../../components/tab-heading'

const BreadcrumbStyled = styled(Box)`
  ${({ theme }) => `
    padding: ${theme.spacing(2)};
    border-bottom: solid 1px ${theme.palette.divider};
    
    .title-breadcrumb {
      margin-left: ${theme.spacing(2)};
    }
    
    .cursor {
      cursor: pointer;
    }
  `}
`

const Heading = function Heading(props) {

	const { account, activeIg, activeThisIg, save, openForm, setOpenForm, createNew } = props
	const { InstagramInsta: instagram } = account

	const [changePage, setChangePage] = useState(false)

	function clickOnPage () {
		if (_.filter(instagram, {isConnected: true}).length > 1) {
			setChangePage(true)
		}
	}

	function changeActivePage (page) {
		activeThisIg(page)
		setChangePage(false)
	}

	return (
		<>
			<BreadcrumbStyled>
				<Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
					<Typography sx={{ display: 'flex', alignItems: 'center' }} color="inherit">
						<HomeWorkOutlinedIcon />
						<span className={'title-breadcrumb'}>Instagram yang sedang tetapkan</span>
					</Typography>
					{
						_.filter(instagram, {isConnected: true}).length === 1 && (
							<Typography color={'primary'}>{activeIg.igName}</Typography>
						)
					}
					{
						_.filter(instagram, {isConnected: true}).length > 1 && (
							<Tooltip arrow title='Klik sini untuk tukar Instragram'>
								<Typography className={'cursor'} color={'primary'} onClick={clickOnPage}>
									{activeIg.igName}
								</Typography>
							</Tooltip>
						)
					}
				</Breadcrumbs>
			</BreadcrumbStyled>

			<TabHeading
				className="heading"
				title="Tetapan Auto Reply Instagram"
				description="Tetapan auto reply untuk Instagram"
				titleProps={{
					sm: 12,
					md: 7,
					lg: 8,
				}}
				btnContainerProps={{
					sm: 12,
					md: 5,
					lg: 4
				}}>
				<HeadingBtn openForm={openForm} setOpenForm={setOpenForm} createNew={createNew} save={save} />
			</TabHeading>

			{
				changePage && (
					<ChangeIgDialog
						insta={instagram}
						activeIg={activeIg}
						onClose={() => setChangePage(false)}
						changeActivePage={changeActivePage}
					/>
				)
			}
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		...state.instagram,
		account: state.account.detail
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		activeThisIg: toBeActivePage => {
			Cookie.set('actig', toBeActivePage.id)
			dispatch({ type: SET_ACTIVE_IG, payload: toBeActivePage })
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Heading)



