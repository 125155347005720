import React from 'react'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Alert from '@mui/material/Alert'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import Avatar from '@mui/material/Avatar'

import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';

const StyleFormControl = styled(FormControl)`
  ${({theme}) => `
  	padding: ${theme.spacing(2)};
  
    &.div {
    	margin-bottom: ${theme.spacing(4)};
    }
  `}
`

const StyleAvatarBlock = styled(Box)`
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	
	> div {
    height: 100%;
    width: 100%;
		
		svg {
			font-size: 3.5rem;
		}
	}
`


export default function Tab3(props) {

	const {formObj, setFormObj, error} = props
	const {embedTitle, embedDescription, embedImg} = formObj

	function update (e) {
		const {name, value} = e.target
		const formObjClone = {...formObj}

		formObjClone[name] = value
		setFormObj(formObjClone)
	}

	return (
		<Box>

			<Alert severity={'info'}>
				Metadata tambahan yang akan terpapar sewaktu pautan ini di&quot;embed&quot; oleh Facebook atau mana-mana servis.
				Anda boleh biarkan bahagian ini seperti dibawah
			</Alert>

			<StyleFormControl fullWidth>

				<TextField
					fullWidth
					label="Tajuk pautan"
					variant="outlined"
					margin="normal"
					name={'embedTitle'}
					value={embedTitle}
					inputProps={{ maxLength: 255 }}
					onChange={update}
					InputLabelProps={{ shrink: true }}/>

				<TextField
					fullWidth
					multiline
					label="Deskripsi pautan"
					variant="outlined"
					margin="normal"
					rows={4}
					name={'embedDescription'}
					value={embedDescription}
					onChange={update}
					InputLabelProps={{ shrink: true }}/>

				<Grid container spacing={2}>
					<Grid item xs={3} md={1}>
						<StyleAvatarBlock>
							<Avatar src={embedImg} sx={{ bgcolor: 'transparent' }}>
								<ErrorOutlinedIcon color={'error'} />
							</Avatar>
						</StyleAvatarBlock>
					</Grid>
					<Grid item xs={9} md={11}>
						<TextField
							fullWidth
							label="Logo pautan"
							variant="outlined"
							margin="normal"
							name={'embedImg'}
							value={embedImg}
							error={error === 1}
							inputProps={{ maxLength: 255 }}
							onChange={update}
							InputLabelProps={{ shrink: true }}/>
					</Grid>
				</Grid>

			</StyleFormControl>

		</Box>
	)
}
