import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'

import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import tabWrapperStyle from '../../../styles/tab-wrapper'

import Tab1 from './tab1'
import Tab2 from './tab2'
import Tab3 from './tab3'
import Tab4 from './tab4'
import {SET_MISC} from '../../../constant'

const StyledBox = styled(Box)`
  ${({theme}) => `
    ${tabWrapperStyle(theme)}
  `}
`

const FormWhatsapp = function FormWhatsapp(props) {

	const {formObj, setFormObj, error, setError, code, setSnackbarMsg, removeTid} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const [tabIndex, setTabIndex] = useState(0)
	
	useEffect(() => {

		if (error === 1) {
			setTabIndex(0)
		} else if (error === 2) {
			setTabIndex(1)
		}
		
	}, [error])

	return (
		<StyledBox>
			<Box className='tab-wrapper'>
				<Tabs
					orientation={isMobile ? 'horizontal' : 'vertical'}
					value={tabIndex}
					onChange={(e, i) => setTabIndex(i)}
					className='tabs' >
					<Tab label='Utama' />
					<Tab label='Agen' />
					<Tab label='Tambahan' />
					<Tab label='Notifikasi' />
				</Tabs>
				{
					tabIndex === 0 && (
						<Tab1 formObj={formObj} setFormObj={setFormObj} error={error} setError={setError} />
					)
				}
				{
					tabIndex === 1 && (
						<Tab2 formObj={formObj} setFormObj={setFormObj} error={error} setError={setError} />
					)
				}
				{
					tabIndex === 2 && (
						<Tab3 formObj={formObj} setFormObj={setFormObj} />
					)
				}
				{
					tabIndex === 3 && <Tab4 formObj={formObj} removeTid={removeTid} code={code} setSnackbarMsg={setSnackbarMsg} />
				}
			</Box>

		</StyledBox>
	)
}

const mapStateToProps = (state) => {
	return {
		prospects: state.prospects
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		setSnackbarMsg: (snackbarMessage = null) => {
			dispatch({ type: SET_MISC, payload: { snackbarMessage } })
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FormWhatsapp)