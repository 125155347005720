import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'
import {Link} from 'react-router-dom'

import {styled} from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

import FacebookBreadcrumbs from './heading'
import FacebookSetup from './setup'
import Tab1 from './tab_1'
import Tab2 from './tab_2'
import Tab3 from './tab_3'
import Tab4 from './tab_4'
import Tab5 from './tab_5'
import Tab6 from './tab_6'

import {getFacebookDetail} from '../../actions/facebook'

const StyleAppBar = styled(AppBar)`
  ${({ theme }) => `
    box-shadow: none;
    border-bottom: 1px dotted ${theme.palette.divider};
    border-top: 1px dotted ${theme.palette.divider};
    
    @media (max-width: ${theme.breakpoints.values.md}px) {
    	max-width: 100vw;
    }
  `}
`

const StyledBox = styled(Box)`
  ${({ theme }) => `
    height: 100%;
  `}
`

const AlertStyled = styled(Alert)`
  ${({theme}) => `
    margin: ${theme.spacing(2)};
    
    a {
      text-decoration: none;
      color: ${theme.palette.primary.main};
      font-weight: 700;
    }
  `}
`

const Facebook = function Facebook(props) {

  const { account: {FacebookPages: pages}, facebook: {activePage}, getFacebookDetail } = props

  const [tabIndex, setTabIndex] = useState(0)
	
	useEffect(() => {

		if (!_.isUndefined(activePage.id)) {
			getFacebookDetail()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activePage.id])

	return (
    <>
      {
        _.isEmpty(pages) ? (
          <FacebookSetup />
        ) : (
          <>
						{
							!_.isEmpty(_.filter(pages, {isConnected: true})) ? (
								<>
									<FacebookBreadcrumbs />

									<StyleAppBar position='static' color={'transparent'}>
										<Tabs
											value={tabIndex}
											onChange={(e, v) => setTabIndex(v)}
											indicatorColor='primary'
											textColor='primary'
											variant='scrollable'
											aria-label='full width tabs example'
											scrollButtons={'auto'}
										>
											<Tab label='Auto Reply' />
											<Tab label='Tag' />
											<Tab label='Abai' />
											<Tab label='Extra' />
											<Tab label='Debug' disabled />
											<Tab label='Ban' disabled />
										</Tabs>
									</StyleAppBar>

									<StyledBox>
										{
											tabIndex === 0 && <Tab1 />
										}
										{
											tabIndex === 1 && <Tab2 />
										}
										{
											tabIndex === 2 && <Tab3 />
										}
										{
											tabIndex === 3 && <Tab4 />
										}
										{
											tabIndex === 4 && <Tab5 />
										}
										{
											tabIndex === 5 && <Tab6 />
										}
									</StyledBox>
								</>
							) : (
								<>
									<AlertStyled severity={'warning'}>
										<AlertTitle>Tiada Facebook Pages yang aktif</AlertTitle>
										Tiada Facebook Pages yang aktif untuk auto reply.
										Sila ke <Link to={'/config?tab=2'}>modul tetapan tab Facebook Pages</Link> untuk buat tetapan Facebook Pages
									</AlertStyled>
								</>
							)
						}
          </>
        )
      }
    </>
	)
}

const mapStateToProps = (state) => {
	return {
		facebook: state.facebook,
		account: state.account.detail
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		getFacebookDetail: () => {
			dispatch(getFacebookDetail())
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Facebook)

