import React, {createRef, useState} from 'react'
import {connect} from 'react-redux'
import Dropzone from 'react-dropzone'
import _ from 'lodash'
import CryptoJS from 'crypto-js'

import {useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import FlakyOutlinedIcon from '@mui/icons-material/FlakyOutlined'

import TabHeading from '../../../components/tab-heading'
import {uploadPhoto} from '../../../../actions/photos'
import {SET_MISC} from '../../../../constant'
import VerifyLink from './verify-link'

const Heading = function Heading(props) {

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const {
		tabIndex,
		openForm,
		setOpenForm,
		save,
		createNew,
		photos: {uploadOption},
		activePage,
		setUploadError,
		setLoading,
		uploadSuccess
	} = props

	const [onUploading, setOnUploading] = useState(false)
	const [openVerify, setOpenVerify] = useState(false)

	const dropzoneRef = createRef()

	function onDropAccepted(files) {
		setUploadError(null)

		if (_.isEmpty(uploadOption)) {
			return
		}

		const file = files[0]

		const reader = new FileReader()

		reader.onabort = () => console.log('file reading was aborted')
		reader.onerror = () => console.log('file reading has failed')
		reader.onload = () => {
			setLoading(true)

			const sha1Hash = CryptoJS.SHA1(CryptoJS.lib.WordArray.create(reader.result))

			file.SHA1 = sha1Hash.toString()

			uploadPhoto(uploadOption, file, activePage)
				.then(response => uploadSuccess(_.pick(response.data, ['fileId', 'fileName'])))
				.catch(err => console.error(err))
				.then(() => {
					setOnUploading(false)
					setLoading(false)
				})
		}
		reader.readAsArrayBuffer(file)

	}

	function onDropRejected(files) {
		setUploadError(files[0].errors[0].code)
	}

	function openDialog() {
		setUploadError(null)
		if (dropzoneRef.current) {
			dropzoneRef.current.open()
		}
	}

	return (
		<TabHeading
			className="heading"
			title={'Tetapan auto reply'}
			description={'Tetapan mesej auto reply komen yang diterima di Fb Page anda secara automatik'}
			titleProps={{
				sm: 12,
				md: 7,
				lg: 8,
			}}
			btnContainerProps={{
				sm: 12,
				md: 5,
				lg: 4
			}}>

			<ButtonGroup orientation={isMobile? 'vertical' : 'horizontal'} fullWidth={isMobile}>
				{
					openForm ? (
						<>
							<Button
								variant={'outlined'}
								startIcon={<CancelOutlinedIcon/>}
								color={'warning'}
								onClick={() => setOpenForm(false)}>
								Batal
							</Button>
							<Button variant={'outlined'} startIcon={<SaveOutlinedIcon/>} onClick={save}>Simpan</Button>
							{
								tabIndex === 1 && (
									<Button
										variant={'outlined'}
										color={'secondary'}
										startIcon={<FlakyOutlinedIcon/>}
										onClick={() => setOpenVerify(true)}>
										Semak pautan
									</Button>
								)
							}
							{
								tabIndex === 2 && (
									<Button
										variant={'outlined'}
										color={'secondary'}
										startIcon={<CloudUploadOutlinedIcon/>}
										onClick={openDialog}
										disabled={_.isEmpty(uploadOption) || onUploading}>
										Muat naik
									</Button>
								)
							}
						</>
					) : (
						<Button variant={'outlined'} startIcon={<AddCircleOutlineOutlinedIcon/>} onClick={createNew}>
							Tambah tetapan
						</Button>
					)
				}
			</ButtonGroup>

			<VerifyLink open={openVerify} onClose={() => setOpenVerify(false)} />

			<Dropzone
				noClick
				noKeyboard
				ref={dropzoneRef}
				accept={'image/jpeg,image/png'}
				maxFiles={1}
				maxSize={1000000}
				multiple={false}
				onDropAccepted={onDropAccepted}
				onDropRejected={onDropRejected}>
				{
					({getRootProps, getInputProps}) => {
						return (
							<div {...getRootProps({className: 'dropzone'})}>
								<input {...getInputProps()} />

							</div>
						)
					}
				}
			</Dropzone>
		</TabHeading>
	)
}

const mapStateToProps = (state) => {
	return {
		photos: {...state.photos},
		...state.facebook
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		setLoading: (showLoading = false) => {
			dispatch({type: SET_MISC, payload: {showLoading}})
		},
		uploadPhoto
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Heading)