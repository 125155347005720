import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'

import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'

import tabWrapperStyle from '../../styles/tab-wrapper'

import TabHeading from '../components/tab-heading'

import SubTab1 from './facebook_pages'
import SubTab2 from './instagram'
import {SET_MISC} from '../../constant'
import {getProspect, updateProspect} from '../../actions/prospects'


const StyledBox = styled(Box)`
  ${({theme}) => `
    ${tabWrapperStyle(theme)}
  `}
`

const Tab1 = function Tab1(props) {

	const {account, prospects, setLoading, getProspect, saveProspectDetail, setSnackbarMsg} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const [tabIndex, setTabIndex] = useState(0)
	const [confData, setConfData] = useState([])
	const [formObj, setFormObj] = useState({
		pageId: 0,
		fetch: true,
		autoHideProspect: true,
		message: '',
		whatsappMsg: '',
		tId: null,
		code: `/Bizsaya link FB ${account.fbId}`
	})

	useEffect(() => {

		if (_.isEmpty(prospects.configuration) && !prospects.fetched) {
			setLoading(true)
			getProspect()
				.then(() => {})
				.catch(err => {})
				.then(() => setLoading(false))
		} else {
			const onView = _.find(prospects.configuration, {pageId: formObj.pageId})
			if (onView) {
				const newDetail = {...onView}
				newDetail.code = `/Bizsaya link P${onView.pageId} ${account.fbId}`
				setFormObj(newDetail)
			} else {
				let allTId = true
				prospects.configuration.forEach(c => {
					if (_.isNull(c.tId)) {
						allTId = false
					}
				})
				const formObjClone = {...formObj}
				formObjClone.tId = allTId? '12345' : null
				setFormObj(formObjClone)
			}
			
			setConfData(JSON.parse(JSON.stringify(prospects.configuration)))
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [prospects.configuration])
	
	useEffect(() => {

		const selectedConfData = _.find(confData, {pageId: formObj.pageId})
		if (selectedConfData) {
			const newDetail = {...selectedConfData}
			newDetail.code = `/Bizsaya link P${newDetail.pageId} ${account.fbId}`
			setFormObj(newDetail)
		} else {
			let allTId = true
			confData.forEach(c => {
				if (_.isNull(c.tId)) {
					allTId = false
				}
			})

			setFormObj({
				pageId: 0,
				fetch: true,
				autoHideProspect: true,
				message: '',
				whatsappMsg: '',
				tId: allTId? '123456' : null,
				code: `/Bizsaya link FB ${account.fbId}`
			})
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formObj.pageId])

	function save(overWrite = null) {

		const confDataClone = JSON.parse(JSON.stringify(confData))

		if (overWrite) {
			const index = _.findIndex(confDataClone, {pageId: formObj.pageId})
			if (index !== -1) {
				confDataClone[index].tId = null
			} else {
				confDataClone.map(c => {
					c.tId = null
					return c
				})
			}
		} else {
			const index = _.findIndex(confDataClone, {pageId: formObj.pageId})
			if (index !== -1) {
				confDataClone[index] = {...formObj}
			} else {
				confDataClone.map(c => {
					const formObjClone = {...formObj}
					c.fetch = formObjClone.fetch
					c.autoHideProspect = formObjClone.autoHideProspect
					c.message = formObjClone.message
					c.whatsappMsg = formObjClone.whatsappMsg
					return c
				})
			}

		}

		setLoading(true)
		saveProspectDetail(confDataClone)
			.then(() => setSnackbarMsg('Maklumat prospek telah dikemaskini'))
			.catch(err => console.error(err.toString()))
			.then(() => setLoading(false))

	}

	let disable = false
	if (_.isEmpty(_.filter(account.FacebookPages, {isEnable: true}))) {
		disable = true
	}

	return (
		<StyledBox>
			<TabHeading
				title="Tetapan prospek"
				description="Carian nombor telefon daripada mana-mana engagement channel"
				titleProps={{sm: 12, md: 7}}
				btnContainerProps={{sm: 12, md: 5}}>
				<Button
					color="primary"
					variant="outlined"
					startIcon={<SaveOutlinedIcon/>}
					onClick={() => save()}
					disabled={disable}>
					Simpan
				</Button>
			</TabHeading>

			<Box className='tab-wrapper'>
				<Tabs
					orientation={isMobile ? 'horizontal' : 'vertical'}
					variant='scrollable'
					value={tabIndex}
					onChange={(e, i) => setTabIndex(i)}
					aria-label='Configurations'
					className='tabs' >
					<Tab label='Facebook Page' />
					<Tab label='Instagram' disabled />
				</Tabs>
				{
					tabIndex === 0 && (
						<SubTab1 formObj={formObj} setFormObj={setFormObj} save={save} />
					)
				}
				{
					tabIndex === 1 && (
						<SubTab2 />
					)
				}
			</Box>

		</StyledBox>
	)
}

const mapStateToProps = (state) => {
	return {
		account: state.account.detail,
		prospects: state.prospects
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		setLoading: (showLoading = false) => {
			dispatch({ type: SET_MISC, payload: { showLoading } })
		},
		setSnackbarMsg: (snackbarMessage = null) => {
			dispatch({ type: SET_MISC, payload: { snackbarMessage } })
		},
		saveProspectDetail: forms => {
			return dispatch(updateProspect(forms))
		},
		getProspect: () => {
			return dispatch(getProspect())
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Tab1)