import Cookie from 'js-cookie'

export default () => {
  Cookie.remove('actpg')
  Cookie.remove('ssid')

  const { hostname } = window.location
  let portalUrl = 'https://bizsaya.com'
  if (hostname === 'localhost') {
    portalUrl = 'https://localhost:4001'
  } else if (hostname === 'app2.bizsaya.com') {
    portalUrl = 'https://portal.bizsaya.com'
  }

  window.location.href = portalUrl
}
