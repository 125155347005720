import _ from 'lodash'
import {
  SET_FACEBOOK,
  SET_ACTIVE_FB_PAGE,
  ADD_NEW_AUTO_REPLY,
  UPDATE_AUTO_REPLY,
  DELETE_AUTO_REPLY
} from '../../constant'

const initialState = {
  activePage: {},
  configuration: {
    autoHideProspect: true,
    likeAll: true,
    tagMessage: null,
    tagToAll: true,
    tagToSpecific: true
  },
  autoReplies: [],
  ignores: []
}

export default function FacebookReducer (state = initialState, action) {
  switch (action.type) {
    case SET_FACEBOOK: {
      return { ...state, ...action.payload }
    }
    case SET_ACTIVE_FB_PAGE: {
      return { ...state, activePage: action.payload || {} }
    }
    case ADD_NEW_AUTO_REPLY: {
      const autoReplies = [...state.autoReplies]
      autoReplies.push(action.payload)
      return { ...state, autoReplies: [...autoReplies] }
    }
    case UPDATE_AUTO_REPLY: {
      const autoReplies = [...state.autoReplies]
      const index = _.findIndex(autoReplies, ['id', action.payload.id])
      if (index !== -1) {
        autoReplies[index] = action.payload
      }
      return { ...state, autoReplies: [...autoReplies] }
    }
    case DELETE_AUTO_REPLY: {
      const autoReplies = [...state.autoReplies]
      const index = _.findIndex(autoReplies, ['id', action.payload])
      if (index !== -1) {
        autoReplies.splice(index, 1)
      }
      return { ...state, autoReplies: [...autoReplies] }
    }
    default: {
      return state
    }
  }
}
