import React from 'react'
import {connect} from 'react-redux'

import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

const InnerTabConfig = function InnerTabConfig(props) {

	const {formObj, setFormObj} = props

	function handleChange(e, v) {
		const formObjClone = {...formObj}
		formObjClone[e.target.name] = v

		setFormObj(formObjClone)
	}

	return (
		<>
			<FormControl component="fieldset" variant="standard">
				<FormGroup>
					<FormControlLabel
						control={<Switch checked={formObj.fetch} onChange={handleChange} name="fetch"/>}
						label="Cari nombor telefon daripada comment dan inbox Facebook Page"
					/>
					<FormControlLabel
						control={<Switch checked={formObj.autoHideProspect} onChange={handleChange} name="autoHideProspect"/>}
						label="Sembunyikan (hide) komen yang diberikan sekiranya terdapat nombor telefon"
					/>
				</FormGroup>
			</FormControl>
		</>
	)
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InnerTabConfig)