import React, {useState} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Alert from '@mui/material/Alert'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

const StyledDiv = styled(Box)`
  ${({theme}) => `
    padding: ${theme.spacing(2)};
    
    .paper-container {
    	max-width: 150px;
    	overflow: hidden;
    	
    	img {
    		height: 130px;
    		cursor: pointer;
    	}
    	
    	.btn-container {
    		padding: ${theme.spacing(1)};
    	}
    }
  `}
`

const SubTab3 = function SubTab3(props) {

	const {formObj, setFormObj, uploadOption, uploadError} = props

	const [img, setImg] = useState(null)

	let msg = ''
	if (uploadError === 'file-invalid-type') {
		msg = 'Hanya gambar berformat jpeg/png dan bersaiz kurang daripada 1MB yang dibenarkan untuk dimuat naik'
	} else if (uploadError === 'file-too-large') {
		msg = 'Gambar terlalu besar. Saiz gambar mestilah kurang daripada 1MB'
	}

	function removeImg (photo) {
		const formObjClone = {...formObj}

		const toBeDelete = JSON.parse(JSON.stringify(formObjClone.toDeletePhotos))
		const index = _.findIndex(toBeDelete, {fileId: photo.fileId})

		if (index === -1) {
			toBeDelete.push(photo)
		} else {
			toBeDelete.splice(photo)
		}

		formObjClone.toDeletePhotos = toBeDelete

		setFormObj(formObjClone)
	}

	return (
		<Box>
			{
				!_.isEmpty(uploadOption) ? (
					<>
						<Alert severity={'info'}>
							Semua gambar yang ada dibawah akan digunakan untuk auto reply. Sekiranya anda memuat naik lebih daripada 1 gambar,
							Hanya satu gambar akan digunakan untuk balas satu-satu komen.
							Pemilihan gambar adalah secara rawak.
							Gambar hanya boleh digunakan pada auto reply ke komen sahaja.
							Sekiranya anda tidak ingin sebarang gambar digunakan untuk auto reply ini. Sila biarkan bahagian ini kosong.
						</Alert>

						{
							!_.isEmpty(_.trim(msg)) && (
								<Alert severity={'error'}>{msg}</Alert>
							)
						}

						<StyledDiv>
							<Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
								{
									formObj.photos.map((p,i) => {
										const index = _.findIndex(formObj.toDeletePhotos, {fileId: p.fileId})
										return (
											<Grid key={i} item>
												<Paper className={'paper-container'}>
													<img key={i} src={`https://static.bizsaya.com/file/bizsaya-public/${p.fileName}`} alt={'auto reply'} onClick={() => setImg(p.fileName)} />
													<Box className={'btn-container'}>
														<Button color={index === -1 ? 'error' : 'secondary'} variant={'outlined'} fullWidth onClick={() => removeImg(p)}>
															{
																index === -1 ? 'Padam' : 'Batal Padam'
															}
														</Button>
													</Box>
												</Paper>
											</Grid>
										)
									})
								}
							</Grid>
						</StyledDiv>
					</>
				) : (
					<Alert severity={'warning'}>Sila tunggu sebentar untuk mendapatkan maklumat muat naik</Alert>
				)
			}

			{
				img && (
					<Dialog open={true} onClose={() => setImg(null)} maxWidth={'md'}>
						<DialogContent>
							<img src={`https://static.bizsaya.com/file/bizsaya-public/${img}`} alt={'auto reply'} style={{width: '100%'}} />
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setImg(null)}>Tutup</Button>
						</DialogActions>
					</Dialog>
				)
			}

		</Box>
	)
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SubTab3)