import React from 'react'
import {connect} from 'react-redux'
import {CopyToClipboard} from 'react-copy-to-clipboard'

import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'

const Copy = function Copy(props) {

	const {code, onCopy} = props

	return (
		<>
			<TextField
				margin={'normal'}
				fullWidth
				label='Kod arahan'
				defaultValue={code}
				variant='outlined'
			/>

			<CopyToClipboard text={code} onCopy={onCopy}>
				<Button
					fullWidth
					variant='outlined'
					color='primary'
					startIcon={<FileCopyOutlinedIcon />}>
					Salin kod arahan diatas secara automatik
				</Button>
			</CopyToClipboard>
		</>
	)
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Copy)