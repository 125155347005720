import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'

import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Avatar from '@mui/material/Avatar'
import ListItemAvatar from '@mui/material/ListItemAvatar'

const StyledAlert = styled(Alert)`
  ${({theme}) => `
  	
	`}
`

const SwapPageDialog = function SwapPageDialog(props) {

	const {onClose, pageToSwap, facebookPagesFromFB, swapThePage} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const [pages, setPages] = useState()
	const [selected, setSelected] = useState({})

	useEffect(() => {

		const pagesToLoad = []
		facebookPagesFromFB.forEach(page => {
			if (page.id !== pageToSwap.fbId) {
				pagesToLoad.push({...page})
			}
		})
		setPages(pagesToLoad)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	function select (page) {
		setSelected(page.id === selected.id? {} : page)
	}

	return (
		<Dialog open={true} onClose={onClose} maxWidth={'sm'} fullScreen={isMobile}>
			<DialogTitle>Tukar ke Facebook Page</DialogTitle>
			{
				_.isEmpty(pages) ? (
					<DialogContent>
						<StyledAlert severity={'warning'}>
							Tiada Facebook Pages yang boleh ditukar dengan Facebook Page {pageToSwap.name}
						</StyledAlert>
					</DialogContent>
				) : (
					<>
						<DialogContent>
							<StyledAlert severity={'info'}>
								Sila klik pada Facebook page yang anda ingin swap
							</StyledAlert>
						</DialogContent>
						<List>
							{
								pages.map((fbPage, i) => {
									return (
										<ListItem key={i} button onClick={() => select(fbPage)} selected={fbPage.id === selected.id}>
											<ListItemAvatar>
												<Avatar src={`https://graph.facebook.com/v2.10/${fbPage.id}/picture`}/>
											</ListItemAvatar>
											<ListItemText primary={fbPage.name}/>
										</ListItem>
									)
								})
							}
						</List>
					</>
				)
			}
			<DialogActions>
				<Button color={'warning'} onClick={onClose}>Batal</Button>
				<Button onClick={() => swapThePage(pageToSwap, selected)} disabled={_.isEmpty(selected)}>Tukar</Button>
			</DialogActions>
		</Dialog>
	)
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SwapPageDialog)