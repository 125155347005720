import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'

import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import tabWrapperStyle from '../../../styles/tab-wrapper'
import Heading from './heading'
import Specifics from './specifics'

import {updateFacebookDetail} from '../../../actions/facebook'
import {SET_MISC} from '../../../constant'

const StyledBox = styled(Box)`
  ${({theme}) => `
    ${tabWrapperStyle(theme)}
  `}
`

const Tab1Box = styled(Box)`
  ${({theme}) => `
    padding: ${theme.spacing(2)};
  `}
`

const Tab2 = function Tab2(props) {

	const {activePage, configuration, setLoading, setSnackbarMsg, updateFacebookDetail} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const [tabIndex, setTabIndex] = useState(0)
	const [tagMessage, setTagMessage] = useState(configuration.tagMessage || '')
	const [formObj, setFormObj] = useState({
		tagToAll: configuration.tagToAll,
		tagToSpecific: configuration.tagToSpecific,
		specificId: configuration.specificId,
		toBeRemove: []
	})

	useEffect(() => {
		const formObjClone = {...formObj}
		formObjClone.specificId = JSON.parse(JSON.stringify(configuration.specificId))

		setFormObj(formObjClone)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [configuration.specificId])

	useEffect(() => {

		if (!_.isUndefined(activePage.id)) {
			setTagMessage(_.clone(configuration.tagMessage) || '')
			setFormObj({
				tagToAll: _.clone(configuration.tagToAll),
				tagToSpecific: _.clone(configuration.tagToSpecific),
				specificId: JSON.parse(JSON.stringify(configuration.specificId)),
				toBeRemove: []
			})
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activePage.id])

	function save() {
		const form = _.pick(formObj, ['tagToAll', 'tagToSpecific', 'specificId'])
		form.tagMessage = tagMessage

		formObj.toBeRemove.forEach(tr => {
			const index = form.specificId.indexOf(tr)
			if (index !== -1) {
				form.specificId.splice(index, 1)
			}
		})
		
		setLoading(true)
		updateFacebookDetail(form)
			.then(() => setSnackbarMsg('Tetapan auto reply tagging dikemaskini'))
			.catch(() => { })
			.then(() => setLoading(false))
	}

	return (
		<StyledBox>

			<Heading save={save}/>

			<Box className="tab-wrapper">
				<Tabs
					orientation={isMobile ? 'horizontal' : 'vertical'}
					variant="scrollable"
					value={tabIndex}
					onChange={(e, i) => setTabIndex(i)}
					aria-label="Configurations"
					className="tabs">
					<Tab label="Mesej"/>
					<Tab label="Post spesifik" disabled={_.isEmpty(_.trim(tagMessage))}/>
				</Tabs>
				{
					tabIndex === 0 && (
						<Box>
							<Alert severity={'info'}>
								Sila masukkan mesej yang akan dibalas sekiranya prospek anda
								Tag rakan mereka didalam komen mereka. Biarkan mesej kosong sekiranya anda tidak
								ingin Bizsaya reply apa-apa sekiranya komen berTag.
								Gunakan kod <code>@sendername</code> untuk mention nama prospek didalam mesej yang akan diberikan
								dan kod <code>@tagged</code> untuk mention nama rakan yang ditag prospek
							</Alert>

							<Tab1Box>
								<TextField
									fullWidth
									multiline
									variant="outlined"
									label="Mesej tag"
									helperText="Mesej yang akan digunakan untuk membalas komen yang mention nama rakan mereka. Biarkan kosong sekiranya anda tidak mahu guna fungsi ini"
									InputLabelProps={{shrink: true}}
									rows={4}
									value={tagMessage}
									margin={'normal'}
									onChange={e => setTagMessage(e.target.value)}
								/>
							</Tab1Box>
						</Box>
					)
				}
				{
					tabIndex === 1 && (
						<Specifics formObj={formObj} setFormObj={setFormObj}/>
					)
				}
			</Box>
		</StyledBox>
	)
}

const mapStateToProps = (state) => {
	return {
		...state.facebook
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		updateFacebookDetail: (form) => {
			return dispatch(updateFacebookDetail(form))
		},
		setLoading: (showLoading = false) => {
			dispatch({type: SET_MISC, payload: {showLoading}})
		},
		setSnackbarMsg: (snackbarMessage = null) => {
			dispatch({type: SET_MISC, payload: {snackbarMessage}})
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Tab2)