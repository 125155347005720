import React from 'react'

import Button from '@mui/material/Button'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'

import TabHeading from '../../../components/tab-heading'

export default function Heading(props) {

	const {save} = props

	return (
		<TabHeading
			className="heading"
			title={'Tetapan auto reply tagging'}
			description={'Tetapan mesej auto reply pada komen yang mention nama rakan mereka secara automatik'}
			titleProps={{
				sm: 12,
				md: 7,
				lg: 8,
			}}
			btnContainerProps={{
				sm: 12,
				md: 5,
				lg: 4
			}}>

			<Button variant={'outlined'} startIcon={<SaveOutlinedIcon/>} onClick={save}>
				Simpan
			</Button>

		</TabHeading>
	)
}