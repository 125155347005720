import dayjs from 'dayjs'
import axios from 'axios'

import requester from './requester'
import {ADD_PHOTOS, PHOTO_UPLOAD_OPTION} from '../constant'
import Cookie from 'js-cookie'

export const getUploadPhotoUrl = () => dispatch => {
  return requester.get('/photos/upload-url')
    .then(response => dispatch({ type: PHOTO_UPLOAD_OPTION, payload: response.data }))
    .catch(err => {
      console.error(err.toString())
    })
}

export const uploadPhoto = (uploadOption, file, activePage) => {
  const extension = file.name.split('.').pop()
  const headers = {
    Authorization: uploadOption.uploading.authorizationToken,
    'X-Bz-File-Name': `Page_${activePage.fbId}/${dayjs().unix()}.${extension}`,
    'Content-Type': file.type,
    'X-Bz-Content-Sha1': file.SHA1
  }

  return axios.post(uploadOption.uploading.uploadUrl, file, { headers })
}

export const savePhoto = file => dispatch => {
  return requester.post('/photos', { file })
    .then(response => {
      dispatch({ type: ADD_PHOTOS, payload: response.data })
      return null
    })
}

export const deletePhoto = data => {
  const pageId = Cookie.get('actpg')
  return requester.delete(`/photos/${pageId}`, {data})
}
