import React, {useEffect, useRef} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

import {getAuthLink} from '../../../actions/facebook'

const StyledBox = styled(Box)`
  ${({theme}) => `
		a {
      text-decoration: none;
      color: ${theme.palette.primary.main};
      font-weight: 700;
    }  
  
    .btn-container {
      display: flex;
      justify-content: center;
      margin-top: ${theme.spacing(3)};
      position: relative;
      
      .overlay {
        position: absolute;
        z-index: 10000;
        width: 250px;
        height: 40px;
        // background-color: #000;
        cursor: pointer;
      }
    }
  `}
`

const NewUser = function NewUser(props) {

	const { getAuthLink } = props

	const timeout = useRef()

	useEffect(() => {

		showLoginBtn()

		return () => {
			if (timeout.current) {
				clearTimeout(timeout.current)
			}
		}

	}, [])

	function showLoginBtn () {
		timeout.current = setTimeout(() => {
			const element = document.getElementById('fb-login-container-loader-uniq')
			if (element) {
				FB.XFBML.parse(element)
			}
		}, 600)
	}

	function begin () {
		getAuthLink()
			.then(response => {
				const {url} = response.data
				if (!_.isEmpty(_.trim(url))) {
					window.location.href = url
				}
			})
			.catch(err => console.error(err))
	}

	return (
		<StyledBox>
			<Alert severity={'success'}>
				<AlertTitle>Selamat datang ke Auto Reply Facebook Page Bizsaya</AlertTitle>
				Sebelum anda boleh memulakan tetapan auto reply untuk Facebook Page,
				Anda perlu benarkan akses Bizsaya ke Facebook Page yang akan digunakan untuk auto reply terlebih dahulu.
				Klik button <i>Continue as &quot;nama-akaun-fb-yang-anda-ingin-link&quot;</i> dibawah untuk membenarkan akses.
				<br />
				Sekiranya anda ingin log masuk dengan Facebook akaun yang lain. Sila
				ke <a href='https://facebook.com' target='_blank' rel='noreferrer'>https://facebook.com</a> terlebih
				dahulu, Logout facebook anda kemudian log masuk menggunakan akaun facebook yang anda inginkan.
				<br />
				Untuk makluman anda juga, Bizsaya hanya meminta &quot;Permission&quot; yang khusus untuk auto reply
				di Facebook Page yang anda benarkan akses. Sekiranya &quot;Permission&quot; yang perlu tersebut
				tidak dibenarkan akses oleh anda, Bizsaya tidak akan proses sebarang auto reply daripada
				Facebook Page anda.
				<br />
				Maklumat yang diberikan adalah terhad dan Bizsaya tidak mempunyai sebarang kepentingan terhadap
				maklumat tersebut. Kesemua maklumat tersebut khusus untuk kegunaan automasi di Bizsaya. Selain
				itu juga, Anda boleh &quot;Opt-out&quot; pada bila-bila masa dengan &quot;Padam akaun saya&quot; di modul
				Maklumat akaun ataupun membuang apps Bizsaya
				di <a href='https://www.facebook.com/settings?tab=business_tools&section=active' target='_blank' rel='noreferrer'>Facebook Apps integration anda</a>.
			</Alert>

			<Box className='btn-container' id='fb-login-container-loader-uniq'>
				<Box className='overlay' onClick={begin} />
				<div
					className='fb-login-button'
					data-size='large'
					data-button-type='continue_with'
					data-layout='default'
					data-auto-logout-link='false'
					data-use-continue-as='true'
					data-height='40'
					data-width='240'
				/>
			</Box>
		</StyledBox>
	)
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		getAuthLink
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NewUser)