import {
  SET_PHOTOS,
  ADD_PHOTOS,
  PHOTO_UPLOAD_OPTION,
  DELETE_PHOTO
} from '../../constant'

const initialState = {
  photos: [],
  uploadOption: {}
}

export default function AccountReducer (state = initialState, action) {
  switch (action.type) {
    case SET_PHOTOS: {
      return { ...state, photos: action.payload }
    }
    case ADD_PHOTOS: {
      const photosClone = JSON.parse(JSON.stringify(state.photos))
      photosClone.push(action.payload)
      return { ...state, photos: photosClone }
    }
    case PHOTO_UPLOAD_OPTION: {
      return { ...state, uploadOption: { ...action.payload } }
    }
    case DELETE_PHOTO: {
      const photosClone = JSON.parse(JSON.stringify(state.photos))
      const index = photosClone.indexOf(action.payload)
      if (index !== -1) {
        photosClone.splice(index, 1)
      }
      return { ...state, photos: photosClone }
    }
    default: {
      return state
    }
  }
}
