import React, { useEffect, useState } from 'react'
import Chart from 'chart.js/auto'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Chip from '@mui/material/Chip'

import TimelineTwoToneIcon from '@mui/icons-material/TimelineTwoTone'
import SyncRoundedIcon from '@mui/icons-material/SyncRounded'

import ChangePage from './change-page'

import styles from './styles'

dayjs.extend(customParseFormat)

const StyledDiv = styled(Card)`
  ${({ theme }) => {
    return `
      ${styles(theme)}
      
      #chartcontainer {
        margin-top: ${theme.spacing(2)};
      }
      
      #selected-page {
        cursor: pointer;
      }
      
      .refresh-container {
        display: flex;
        justify-content: flex-end;
      }
      
      .chip-container {
        align-self: center;
        display: flex;
        justify-content: flex-end;
        
        > div {
          
          > div {
            cursor: pointer;
            margin-right: ${theme.spacing(1)};
           
            &:last-of-type {
              margin-right: ${theme.spacing(0)};
            }
          }
        }
      }
    `
  }}
`

export default function ChartBlocks (props) {
  const { account, pagesData, getStats } = props

  const [myChart, setMyChart] = useState(null)
  const [pageFbId, setPageFbId] = useState(null)
  const [pageName, setPageName] = useState(null)
  const [changePage, setChangePage] = useState(false)
  const [onlyOne, setOneOnly] = useState(true)
  const [month, setMonth] = useState(2)

  useEffect(() => {
    let pageToUse = null
    let totalPage = 0

    account.FacebookPages.forEach(p => {
      if (p.isEnable && p.isActive) {
        pageToUse = p
        totalPage++
      }
    })

    if (pageToUse) {
      setOneOnly(totalPage === 1)

      setPageName(pageToUse.name)
      setPageFbId(pageToUse.fbId)

      const element = document.getElementById('chartcontainer')
      if (element) {
        const canvas = document.createElement('canvas')
        canvas.setAttribute('height', '300px')
        element.appendChild(canvas)
        drawChart(canvas)

        return () => {
          while (element.firstChild) {
            element.firstChild.remove()
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (myChart && pagesData) {
      const stats = _.find(pagesData, ({ fbId: pageFbId }))
      
      if (stats) {
        const statsArr = [...stats.stats]
        statsArr.map(st => {
          st.createdAt = dayjs(st.createdAt)
          return st
        })

        const labels = []
        const dataSets = [...myChart.data.datasets]
        let m = dayjs().startOf('month')

        if (month !== 1) {
          m = dayjs().subtract(month - 1, 'month').startOf('month')
        }

        const until = dayjs().startOf('day').diff(m, 'day')
        for (let i = 0; i < until; i++) {
          const theDay = m.add(i, 'day')
          labels.push(theDay.format('DD-MM-YYYY'))

          dataSets[0].data[i] = 0
          dataSets[1].data[i] = 0
          dataSets[2].data[i] = 0
          dataSets[3].data[i] = 0

          statsArr.forEach(stats => {
            if (theDay.isSame(stats.createdAt)) {
              dataSets[0].data[i] = stats.like
              dataSets[1].data[i] = stats.comment
              dataSets[2].data[i] = stats.reply
              dataSets[3].data[i] = stats.number
            }
          })
        }

        labels.push('Hari ini')
        dataSets[0].data[until] = parseInt(stats.currentStats.like)
        dataSets[1].data[until] = parseInt(stats.currentStats.comment)
        dataSets[2].data[until] = parseInt(stats.currentStats.reply)
        dataSets[3].data[until] = parseInt(stats.currentStats.number)

        myChart.data.labels = labels
        myChart.data.datasets = dataSets
        myChart.update()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myChart, month, pagesData, pageFbId])

  function drawChart (element) {
    let m = dayjs().startOf('month')

    const labels = []

    if (month !== 1) {
      m = dayjs().subtract(month - 1, 'month').startOf('month')
    }

    const until = dayjs().startOf('day').diff(m, 'day')
    for (let i = 0; i < until; i++) {
      labels.push(m.add(i, 'day').format('DD-MM-YYYY'))
    }
    labels.push('Hari ini')

    const datasets = [{
      data: [],
      label: 'Like',
      borderColor: 'rgb(30,144,255)',
      backgroundColor: 'rgb(30,144,255,0.5)'
    }, {
      data: [],
      label: 'Komen',
      borderColor: 'rgb(34,139,34)',
      backgroundColor: 'rgb(34,139,34,0.5)'
    }, {
      data: [],
      label: 'Reply',
      borderColor: 'rgb(255,165,0)',
      backgroundColor: 'rgb(255,165,0,0.5)'
    }, {
      data: [],
      label: 'Nombor telefon',
      borderColor: 'rgb(240,230,140)',
      backgroundColor: 'rgb(240,230,140,0.5)'
    }]

    datasets.map(d => {
      for (let j = 0; j < until; j++) {
        d.data.push(0)
      }
    })

    const config = {
      type: 'line',
      data: { labels, datasets },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom'
          },
          title: {
            display: false
          }
        }
      }
    }

    const c = new Chart(element, config)

    setMyChart(c)
  }

  function triggerChangePage () {
    if (!onlyOne) {
      setChangePage(true)
    }
  }

  function changeActivePage (page) {
    setChangePage(false)

    setPageName(page.name)
    setPageFbId(page.fbId)
  }

  function refresh () {
    getStats({ id: pageFbId })
      .then(response => {
        const dataSets = [...myChart.data.datasets]

        dataSets[0].data[dataSets[0].data.length - 1] = response.data.like
        dataSets[1].data[dataSets[1].data.length - 1] = response.data.comment
        dataSets[2].data[dataSets[2].data.length - 1] = response.data.reply
        dataSets[3].data[dataSets[3].data.length - 1] = response.data.number

        myChart.data.datasets = dataSets
        myChart.update()
      })
      .catch(err => {
        console.error(err)
      })
  }

  return (
    <StyledDiv variant="outlined">
      <CardContent>
        <Grid container>
          <Grid item md={8} lg={9}>
            <div className='title'>
              <TimelineTwoToneIcon />
              <Typography gutterBottom variant='h5'>
								Statistik
                <small><small id={`${onlyOne ? '' : 'selected-page'}`} onClick={triggerChangePage}> - {pageName}</small></small>
              </Typography>
            </div>
            <Typography variant='caption'>
							Statistik semasa facebook page anda.
              {
                !onlyOne && (
                  <span>Klik pada nama facebook page anda untuk menukar facebook page lain.</span>
                )
              }
            </Typography>
          </Grid>
          <Grid item md={4} lg={3} className='chip-container'>
            {
              myChart && (
                <Box>
                  <Chip
                    size={'small'}
                    label={'1 bulan'}
                    onClick={() => setMonth(1)}
                    variant={month === 1? 'default' : 'outlined'}
                    color={month === 1? 'primary' : 'default'} />
                  <Chip
                    size={'small'}
                    label={'2 bulan'}
                    onClick={() => setMonth(2)}
                    variant={month === 2? 'default' : 'outlined'}
                    color={month === 2? 'primary' : 'default'} />
                  <Chip
                    size={'small'}
                    label={'3 bulan'}
                    onClick={() => setMonth(3)}
                    variant={month === 3? 'default' : 'outlined'}
                    color={month === 3? 'primary' : 'default'} />
                  <IconButton size={'small'} onClick={refresh}>
                    <SyncRoundedIcon />
                  </IconButton>
                </Box>
              )
            }
          </Grid>
        </Grid>
        <div id='chartcontainer' />
      </CardContent>

      {
        changePage && (
          <ChangePage
            pageFbId={pageFbId}
            pages={account.FacebookPages}
            changeActivePage={changeActivePage}
            onClose={() => setChangePage(false)}
          />
        )
      }
    </StyledDiv>
  )
}
