import React from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'
import { Route, Switch } from 'react-router-dom'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

import NavigationAppBar from './modules/navigations/appbar'
import DrawerApp from './modules/navigations/drawer'
import BottomNavigations from './modules/navigations/bottom-appbar'
import SnackbarNotification from './modules/navigations/snackbar'
import ReconnectAlert from './modules/reconnect/alert'
import Homepage from './modules/homepage'
import Facebook from './modules/facebook'
import Prospects from './modules/prospects'
import Whatsapp from './modules/whatsapp'
import Instagram from './modules/instagram'
import Configuration from './modules/configuration'
import ConfigurationCallback from './modules/configuration/callback'
import Reconnect from './containers/reconnect'
import PaymentsCallback from './modules/configuration/payment-callbacks'
import Logout from './modules/logout'
import Error404 from './modules/error/404'



const StyledBoxMain = styled(Box)`
  ${({theme}) => {
		return `
			display: flex;
			padding-top: ${theme.mixins.toolbar['@media (min-width:0px) and (orientation: landscape)'].minHeight}px;
			flex-grow: 1;
			z-index: 1;
			
			@media (max-width: ${theme.breakpoints.values.md}px) {
				padding-top: 0px;
				max-height: calc(100vh - ${theme.mixins.toolbar['@media (min-width:0px) and (orientation: landscape)'].minHeight + theme.mixins.toolbar.minHeight}px);
				overflow: auto;
				margin-top: ${theme.mixins.toolbar['@media (min-width:0px) and (orientation: landscape)'].minHeight}px;
			}
		`
	}}
`

const StyledBoxContent = styled(Box)`
  flex-grow: 1;
	display: flex;
	flex-direction: column;
`

const StyledBox = styled(Box)`
  ${({theme}) => `
    width: 100%;
		padding: ${theme.spacing(2)};
  `}
`

const Content = function Content(props) {

	const { account } = props

	return (
		<>
			{
				!_.isEmpty(account) && (
					<>
						<NavigationAppBar />

						<StyledBoxMain>
							<DrawerApp />

							<StyledBoxContent>

								<ReconnectAlert />

								<Switch>
									<Route path='/' exact component={Homepage} />
									<Route path='/fb' exact component={Facebook} />
									<Route path='/fb-callback' exact component={Facebook} />
									<Route path='/prospects' exact component={Prospects} />
									<Route path='/whatsapp' exact component={Whatsapp} />
									<Route path='/ig' exact component={Instagram} />
									<Route path='/config' exact component={Configuration} />
									<Route path='/config-callback' exact component={ConfigurationCallback} />
									<Route path='/config-ig-callback' exact component={ConfigurationCallback} />
									<Route path='/reconnect-callback' exact component={Reconnect} />
									<Route path='/payments/callback' exact component={PaymentsCallback} />
									<Route path='/logout' exact component={Logout} />
									<Route component={Error404} />
								</Switch>
							</StyledBoxContent>
						</StyledBoxMain>

						<SnackbarNotification />

						<BottomNavigations />
					</>
				)
			}
			{
				_.isEmpty(account) && (
					<StyledBox>
						<Alert severity="info">
							<AlertTitle>Info</AlertTitle>
							Memulakan aplikasi. Sila tunggu sebentar...
						</Alert>
					</StyledBox>
				)
			}
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		account: state.account.detail
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Content)