import React, {useState} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'

import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'

import DeleteConfirmationDialog from './delete-confirmation-dialog'

const StyledBox = styled(Box)`
  ${({theme}) => `
    padding: ${theme.spacing(2)};
  `}
`

const ChipsBox = styled(Box)`
  ${({theme}) => `
  	display: flex;
  	flex-wrap: wrap;
  	justify-content: flex-start;
  	align-items: center;
  	padding-top: ${theme.spacing(1)};
  	margin: 0px -${theme.spacing(0.5)};
  	
  	> div {
			margin: ${theme.spacing(0.5)};
  	}
  `}
`

const BtnContainer = styled(Box)`
  ${({theme}) => `
  	display: flex;
  	justify-content: flex-end;
    padding: ${theme.spacing(1)};
  `}
`

const ListAutoReplies = function ListAutoReplies(props) {

	const {autoReplies, editThis, confirmDeletion} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const [isDelete, setIsDelete] = useState(null)

	function checking (keywords) {
		return _.isArray(keywords)? keywords : keywords.split(',')
	}

	function confirmDelete () {
		confirmDeletion(isDelete.id)
		setIsDelete(null)
	}

	return (
		<StyledBox>
			{
				_.isEmpty(autoReplies) ? (
					<Alert severity={'info'}>
						Tiada tetapan auto reply instagram lagi
					</Alert>
				) : (
					<>
						<Grid container spacing={2}>
							{
								autoReplies.map(ar => {
									return (
										<Grid item key={ar.id} xs={12} sm={6} md={4}>
											<Card variant="outlined">
												<CardContent>
													<Typography variant={'h6'}>{ar.label}</Typography>
													<Typography variant={'caption'}>{ar.messageComment}</Typography>
													{
														!_.isEmpty(ar.keywords) && (
															<ChipsBox>
																{
																	checking(ar.keywords).map((keyword, i) => {
																		return (
																			<Chip key={i} label={keyword} size={'small'} variant="outlined" />
																		)
																	})
																}
															</ChipsBox>
														)
													}
												</CardContent>
												<Divider />
												<BtnContainer>
													<ButtonGroup orientation={isMobile? 'vertical' : 'horizontal'} fullWidth={isMobile} variant={'outlined'}>
														<Button color={'error'} onClick={() => setIsDelete(ar)}>Padam</Button>
														<Button onClick={() => editThis(ar)}>Kemaskini</Button>
													</ButtonGroup>
												</BtnContainer>
											</Card>
										</Grid>
									)
								})
							}
						</Grid>
					</>
				)
			}

			{
				!_.isEmpty(isDelete) && (
					<DeleteConfirmationDialog onClose={() => setIsDelete(null)} confirm={confirmDelete} />
				)
			}
		</StyledBox>
	)
}

const mapStateToProps = (state) => {
	return {
		...state.instagram
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ListAutoReplies)