import React from 'react'
import {connect} from 'react-redux'

import TabHeading from '../../../components/tab-heading'
import Button from '@mui/material/Button'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'

const Heading = function Heading(props) {

	const {createNew} = props

	return (
		<TabHeading
			className="heading"
			title={'Ban'}
			description={'Abaikan akaun fb daripada di-proses Bizsaya'}
			titleProps={{
				sm: 12,
				md: 7,
				lg: 8,
			}}
			btnContainerProps={{
				sm: 12,
				md: 5,
				lg: 4
			}}>

			<Button variant={'outlined'} startIcon={<AddCircleOutlineOutlinedIcon/>} onClick={createNew}>
				Simpan
			</Button>

		</TabHeading>
	)
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Heading)