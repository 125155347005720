import React, {useState} from 'react'

import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'

const FormControlStyled = styled(FormControl)`
	${({theme}) => `
		margin-top: ${theme.spacing(4)};
	`}
`

export default function DateSelector(props) {

	const {onClose, setMonthPage, month} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const [totalMonth, setTotalMonth] = useState(month)

	function save(month) {
		setMonthPage(month)
		setTotalMonth(month)
	}

	return (
		<Dialog open={true}>
			<DialogContent>
				<Alert severity={'info'}>
					Sila pilih berapa bulan pembayaran untuk Facebook Page ini
				</Alert>

				<FormControlStyled fullWidth>
					<InputLabel id="month-select">Pembayaran untuk</InputLabel>
					<Select
						labelId="month-select"
						value={totalMonth}
						label="Pembayaran untuk"
						onChange={e => save(e.target.value)}
					>
						<MenuItem value={0}>Batalkan penambahan Facebook Page ini</MenuItem>
						<MenuItem value={1}>Sebulan</MenuItem>
						<MenuItem value={2}>2 Bulan</MenuItem>
						<MenuItem value={3}>3 Bulan</MenuItem>
						<MenuItem value={4}>4 Bulan</MenuItem>
						<MenuItem value={5}>5 Bulan</MenuItem>
						<MenuItem value={6}>6 Bulan</MenuItem>
						<MenuItem value={7}>7 Bulan</MenuItem>
						<MenuItem value={8}>8 Bulan</MenuItem>
						<MenuItem value={9}>9 Bulan</MenuItem>
						<MenuItem value={10}>10 Bulan</MenuItem>
						<MenuItem value={11}>11 Bulan</MenuItem>
						<MenuItem value={12}>Setahun</MenuItem>
					</Select>
				</FormControlStyled>
			</DialogContent>
			<DialogActions>
				<Button fullWidth={isMobile} onClick={onClose} color={'warning'}>
					Batal
				</Button>
			</DialogActions>
		</Dialog>
	)
}