import Cookie from 'js-cookie'
import _ from 'lodash'

import requester from './requester'
import {
  ADD_NEW_IG_AUTO_REPLY,
  UPDATE_IG_AUTO_REPLY,
  DELETE_IG_AUTO_REPLY,
  SET_FACEBOOK,
  SET_IG_UPLOAD_OPTION,
  SET_INSTAGRAM
} from '../constant'

export const getAuthLink = (state = undefined) => {
  return requester.get('/instagram/authlink', { params: { state } })
}

export const verifyCode = (code, state) => {
  return requester.post('/instagram/authlink', { code, state })
}

export const confirmPage = pages => {
  return requester.post('/instagram/pages', { pages })
}

export const getInstaDetail = () => dispatch => {
  const pageId = Cookie.get('actig')
  if (_.isEmpty(_.trim(pageId))) {
    return Promise.reject(new Error('Missing page'))
  }

  return requester.get(`/instagram/${pageId}/auto-replies`)
    .then(response => dispatch({ type: SET_INSTAGRAM, payload: response.data }))
    .catch(err => console.error(err))
}

export const createAutoReply = form => dispatch => {
  const pageId = Cookie.get('actig')
  return requester.post(`/instagram/${pageId}/auto-replies`, form)
    .then(response => dispatch({ type: ADD_NEW_IG_AUTO_REPLY, payload: response.data }))
}

export const updateAutoReply = form => dispatch => {
  const pageId = Cookie.get('actig')
  return requester.put(`/instagram/${pageId}/auto-replies/${form.id}`, form)
    .then(response => dispatch({ type: UPDATE_IG_AUTO_REPLY, payload: response.data }))
}

export const deleteAutoReply = id => dispatch => {
  const pageId = Cookie.get('actig')
  return requester.delete(`/instagram/${pageId}/auto-replies/${id}`)
    .then(() => dispatch({ type: DELETE_IG_AUTO_REPLY, payload: id }))
}

export const deleteIgnorePosts = (id) => dispatch => {
  const pageId = Cookie.get('actig')
  const url = id ? `/fb/${pageId}/ignore/${id}` : `/fb/${pageId}/ignore`

  return requester.delete(url)
    .then(response => dispatch({ type: SET_FACEBOOK, payload: { ignores: response.data } }))
}

export const getUploadPhotoUrl = pageIdBck => dispatch => {
  const pageId = Cookie.get('actig')
  return requester.get(`/instagram/${pageId}/auto-replies/upload-url`)
    .then(response => dispatch({ type: SET_IG_UPLOAD_OPTION, payload: response.data }))
    .catch(err => console.error(err.toString()))
}
