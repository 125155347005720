import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'
import {withRouter, Link} from 'react-router-dom'

import {styled} from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'

import menuList from './menu-list'
import MenuIcon from './menu-icon'

const StyledPaper = styled(Paper)`
  ${({theme}) => `
  	position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;
	
		@media (min-width: ${theme.breakpoints.values.md}px) {
			display: none;
		}
		
		a {
			text-decoration: none;
		}
  `}
`

const StyledBottomNavigationAction = styled(BottomNavigationAction)`
	min-width: 90px;
`

const BottomAppbar = function BottomAppbar(props) {

	const {location} = props

	const [menus, setMenus] = useState([])
	const [value] = useState(0)

	useEffect(() => {
		defineActive()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		defineActive()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname])

	function defineActive () {
		const menuListClone = JSON.parse(JSON.stringify(menuList)).slice(0, 4)

		menuListClone.map(menu => {
			menu.active = false
			if (menu.to === '/') {
				if (location.pathname === '/') {
					menu.active = true
				}
			} else {
				const reg = new RegExp(`^\\${menu.to}`)
				menu.active = !_.isNull(location.pathname.match(reg))
			}

			return menu
		})

		setMenus(menuListClone)
	}

	return (
		<StyledPaper elevation={3}>
			<BottomNavigation showLabel value={value}>
				{
					menus.map((menu, i) => {
						return (
							<Link key={i} to={menu.to}>
								<StyledBottomNavigationAction
									selected={menu.active}
									label={menu.label}
									icon={<MenuIcon to={menu.to} />} />
							</Link>
						)
					})
				}
			</BottomNavigation>
		</StyledPaper>
	)
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(BottomAppbar))