import {
  SET_ACCOUNT,
  SET_TOKEN_EXPIRE_AT,
  SET_ACCOUNT_ERROR_CODE,
  SET_FB_PAGE_FROM_FB,
  SET_IG_FROM_FB
} from '../../constant'

const initialState = {
  detail: {},
  facebookPagesFromFBFetch: false,
  facebookPagesFromFB: [],
  instagramFromFBFetch: false,
  instagramFromFB: [],
  tokenExpiredAt: null,
  errorCode: undefined
}

export default function AccountReducer (state = initialState, action) {
  switch (action.type) {
    case SET_ACCOUNT: {
      return { ...state, detail: action.payload }
    }
    case SET_TOKEN_EXPIRE_AT: {
      return { ...state, tokenExpiredAt: action.payload }
    }
    case SET_ACCOUNT_ERROR_CODE: {
      return { ...state, errorCode: action.payload }
    }
    case SET_FB_PAGE_FROM_FB: {
      return {
        ...state,
        ...{
          facebookPagesFromFBFetch: true,
          facebookPagesFromFB: JSON.parse(JSON.stringify(action.payload))
        }
      }
    }
    case SET_IG_FROM_FB: {
      return {
        ...state,
        ...{
          instagramFromFBFetch: true,
          instagramFromFB: JSON.parse(JSON.stringify(action.payload))
        }
      }
    }
    default: {
      return state
    }
  }
}
